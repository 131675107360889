const _jsxFileName = "/usr/src/app/app/js/components/LogoutRoute.tsx";import React from "react";
import { observer } from "mobx-react-lite";
import { useApp } from "../context";
import { ErrorMessage } from "./ErrorMessage";
import { Navigate } from "react-router-dom";

export const LogoutRoute = observer(function LogoutRoute() {
  const app = useApp();

  React.useEffect(() => {
    app.parse.logout();
    app.benchmarks.benchmarks = {};
    app.benchmarks.currentBenchmark = null;
  }, []);

  if (!app.parse.user) {
    return (
      React.createElement(ErrorMessage, {
        icon: "fa:exclamation-circle",
        title: "Berechtigungs Fehler" ,
        message: "Sie sind nicht eingeloggt"   , __self: this, __source: {fileName: _jsxFileName, lineNumber: 18}}
      )
    );
  }

  return React.createElement(Navigate, { to: "/", __self: this, __source: {fileName: _jsxFileName, lineNumber: 26}} );
});

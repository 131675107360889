 function _optionalChain(ops) { let lastAccessLHS = undefined; let value = ops[0]; let i = 1; while (i < ops.length) { const op = ops[i]; const fn = ops[i + 1]; i += 2; if ((op === 'optionalAccess' || op === 'optionalCall') && value == null) { return undefined; } if (op === 'access' || op === 'optionalAccess') { lastAccessLHS = value; value = fn(value); } else if (op === 'call' || op === 'optionalCall') { value = fn((...args) => value.call(lastAccessLHS, ...args)); lastAccessLHS = undefined; } } return value; }import { makeAutoObservable } from "mobx";







export class ReportService {
  

  __init() {this.loading = true}
  __init2() {this.report = null}
  __init3() {this.error = null}

  __init4() {this.lastReport = null}

  constructor(app) {;ReportService.prototype.__init.call(this);ReportService.prototype.__init2.call(this);ReportService.prototype.__init3.call(this);ReportService.prototype.__init4.call(this);
    makeAutoObservable(this);

    this.app = app;
  }

   async init(cache) {
    Object.assign(this, cache);
  }

   get reportUrl() {
    if (!this.lastReport) {
      return "";
    }

    const host = window.location.origin;
    const id = this.lastReport.id;
    const secret = this.lastReport.secret;

    return `${host}/report/${id}/${secret}`;
  }

   get categoryMap() {
    return {
      all: "Alle Kategorien",
      ...Object.fromEntries(
        this.app.benchmarks.categories
          .filter((c) => _optionalChain([this, 'access', _ => _.report, 'optionalAccess', _2 => _2.categoriesCurrent, 'access', _3 => _3[c.objectId]]))
          .map((c) => [c.objectId, c.name])
      ),
    };
  }

   getData(category) {
    if (category === "all") {
      return Object.keys(_optionalChain([this, 'access', _4 => _4.report, 'optionalAccess', _5 => _5.categoriesCurrent]) || {}).map(
        (c) => _optionalChain([this, 'access', _6 => _6.report, 'optionalAccess', _7 => _7.categoriesCurrent, 'access', _8 => _8[c]]) || 0
      );
    } else {
      return this.app.benchmarks.questions
        .filter((q) => _optionalChain([q, 'access', _9 => _9.category, 'optionalAccess', _10 => _10.objectId]) === category)
        .map((q) => _optionalChain([this, 'access', _11 => _11.report, 'optionalAccess', _12 => _12.questionsCurrent, 'access', _13 => _13[q.objectId]]) || 0);
    }
  }

   getDataComparing(category) {
    if (category === "all") {
      return Object.keys(_optionalChain([this, 'access', _14 => _14.report, 'optionalAccess', _15 => _15.categoriesCurrent]) || {}).map(
        (c) => _optionalChain([this, 'access', _16 => _16.report, 'optionalAccess', _17 => _17.categoriesComparing, 'access', _18 => _18[c]]) || 0
      );
    } else {
      return this.app.benchmarks.questions
        .filter((q) => _optionalChain([q, 'access', _19 => _19.category, 'optionalAccess', _20 => _20.objectId]) === category)
        .map((q) => _optionalChain([this, 'access', _21 => _21.report, 'optionalAccess', _22 => _22.questionsComparing, 'access', _23 => _23[q.objectId]]) || 0);
    }
  }

   getLabels(category) {
    if (category === "all") {
      return Object.keys(_optionalChain([this, 'access', _24 => _24.report, 'optionalAccess', _25 => _25.categoriesCurrent]) || {}).map((c) => {
        return this.categoryMap[c];
      });
    } else {
      return this.app.benchmarks.questions
        .filter((q) => _optionalChain([q, 'access', _26 => _26.category, 'optionalAccess', _27 => _27.objectId]) === category)
        .map((q) => q.name_short || "");
    }
  }

   getChart(
    category,
    colorA,
    colorB
  ) {
    const labels = this.getLabels(category);

    return {
      chart: {
        polar: true,
      },

      title: {
        text: undefined,
      },

      subtitle: {
        text: undefined,
      },

      pane: {
        startAngle: 0,
        endAngle: 360,
      },

      xAxis: {
        // tickInterval: 1,
        // min: 0,
        // max: labels.length,
        // labels: {
        //   formatter: function () {
        //     return labels[this.value] || "";
        //   },
        // },

        categories: labels,
        // gridLineWidth: 3,
        // gridLineColor: "#fff",
        gridLineWidth: 1,
        gridZIndex: 20,
      },
      exporting: {
        enabled: false,
      },
      credits: {
        enabled: false,
      },
      yAxis: {
        min: 0,
        max: 4,
        tickInterval: 1,
        gridLineWidth: 1,
        gridZIndex: 20,
        labels: {
          formatter: () => "",
        },
      },

      plotOptions: {
        series: {
          pointStart: 0,
          pointInterval: 1,
        },
        column: {
          pointPadding: 0,
          groupPadding: 0,
          grouping: false,
          shadow: false,
          borderWidth: 0,
        },
      },

      tooltip: {
        shared: true,
      },

      series: [
        {
          name: "Dein Antworten",
          data: this.getData(category),
          type: "scatter",
          // pointPlacement: "between",
          color: colorB,
          pointPadding: 0.2,
          pointPlacement: 0,
          // opacity: 0.6,
          zIndex: 30,

          marker: {
            radius: 5,
          },

          borderColor: "#fff",
          borderWidth: 2,
        },
        {
          name: "Durchschnittliche Antworten",
          data: this.getDataComparing(category),
          type: "column",
          // pointPlacement: "between",
          color: colorA,
          pointPadding: 0,
          pointPlacement: 0,
          // opacity: 0.8,
        },
      ],
    };
  }

   async fetchReport(id, secret) {
    try {
      this.loading = true;

      const { result } = await this.app.parse.cc("report-fetch", {
        id: id,
        secret: secret,
      });

      this.lastReport = { id, secret };

      this.report = result;
      this.error = null;
      this.loading = false;

      this.app.notifications.success("Report geladen");
    } catch (error) {
      this.report = null;
      this.error = error;
      this.loading = false;
    }
  }
}

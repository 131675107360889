const _jsxFileName = "/usr/src/app/app/js/components/SuccessMessage.tsx";import * as React from "react";

import styled from "styled-components";

import { Icon } from "@opendash/icons";

const Container = styled.div`
  width: 100%;
  height: 200px;

  padding: 20px;

  display: flex;
  justify-content: center;
  align-items: center;

  text-align: center;
`;

const IconHolder = styled.div`
  font-size: 2em;
  color: #4caf50;
`;

const Title = styled.div`
  text-transform: uppercase;
  font-weight: 700;
`;

const Message = styled.div`
  margin-bottom: 10px;
`;

export function SuccessMessage({
  icon,
  title,
  message,
}



) {
  return (
    React.createElement(Container, {__self: this, __source: {fileName: _jsxFileName, lineNumber: 44}}
      , React.createElement('div', {__self: this, __source: {fileName: _jsxFileName, lineNumber: 45}}
        , React.createElement(IconHolder, {__self: this, __source: {fileName: _jsxFileName, lineNumber: 46}}
          , React.createElement(Icon, { icon: icon, __self: this, __source: {fileName: _jsxFileName, lineNumber: 47}} )
        )
        , React.createElement(Title, {__self: this, __source: {fileName: _jsxFileName, lineNumber: 49}}, title)
        , React.createElement(Message, {__self: this, __source: {fileName: _jsxFileName, lineNumber: 50}}, message)
      )
    )
  );
}

const _jsxFileName = "/usr/src/app/app/js/components/UserLoginRoute.tsx";import React from "react";
import { observer } from "mobx-react-lite";
import { useApp } from "../context";
import { ErrorMessage } from "./ErrorMessage";
import { Button, TextField, Typography } from "@material-ui/core";
import { useNavigate } from "react-router-dom";

export const UserLoginRoute = observer(function UserLoginRoute() {
  const app = useApp();
  const navigate = useNavigate();

  const [state, setState] = React.useState({
    email: "",
    password: "",
  });

  // tu chuyen den home luon
  if (app.parse.user) {
    return (
      React.createElement(ErrorMessage, {
        icon: "fa:exclamation-circle",
        title: "Bereits Eingeloggt" ,
        message: "...", __self: this, __source: {fileName: _jsxFileName, lineNumber: 20}}
      )
    );
  }

  return (
    React.createElement('div', { style: { width: 400, margin: "0 auto" }, __self: this, __source: {fileName: _jsxFileName, lineNumber: 29}}
      , React.createElement(Typography, { component: "h1", variant: "h4", align: "center", __self: this, __source: {fileName: _jsxFileName, lineNumber: 30}}, "Loggen Sie sich mit Ihrem Unternehmensaccount ein"

      )
      , React.createElement('form', {
        noValidate: true,
        style: {
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          marginTop: "2rem",
        },
        onSubmit: function handleSubmit(event) {
          event.preventDefault();

          app.parse.login(state.email, state.password).then(
            () => {
              app.notifications.success("Anmeldung erfolgreich");
              navigate("/check");
            },
            (error) => {
              app.notifications.error(error.message);
            }
          );
        }, __self: this, __source: {fileName: _jsxFileName, lineNumber: 33}}

        , React.createElement(TextField, {
          variant: "outlined",
          margin: "normal",
          required: true,
          fullWidth: true,
          id: "email",
          label: "E-Mail Adresse" ,
          name: "email",
          autoComplete: "email",
          autoFocus: true,
          onChange: (e) => {
            setState((cur) => ({ ...cur, email: e.target.value }));
          }, __self: this, __source: {fileName: _jsxFileName, lineNumber: 55}}
        )
        , React.createElement(TextField, {
          variant: "outlined",
          margin: "normal",
          required: true,
          fullWidth: true,
          name: "password",
          label: "Passwort",
          type: "password",
          id: "password",
          autoComplete: "current-password",
          onChange: (e) => {
            setState((cur) => ({ ...cur, password: e.target.value }));
          }, __self: this, __source: {fileName: _jsxFileName, lineNumber: 69}}
        )
        , React.createElement(Button, { type: "submit", fullWidth: true, variant: "contained", color: "primary", __self: this, __source: {fileName: _jsxFileName, lineNumber: 83}}, "Anmelden"

        )

        , React.createElement('div', {
          style: {
            display: "flex",
            flexDirection: "row",
            width: "100%",
            alignItems: "center",
            justifyContent: "center",
            marginTop: "1rem",
          }, __self: this, __source: {fileName: _jsxFileName, lineNumber: 87}}

          , React.createElement(Typography, { variant: "body2", __self: this, __source: {fileName: _jsxFileName, lineNumber: 97}}, "Noch kein Account?"  )
          , React.createElement(Button, {
            style: { padding: "0 0.5rem" },
            color: "primary",
            size: "small",
            onClick: () => {
              navigate("/userRegister");
            }, __self: this, __source: {fileName: _jsxFileName, lineNumber: 98}}
, "Account erstellen"

          )
        )

        , React.createElement('div', {
          style: {
            display: "flex",
            flexDirection: "column",
            width: "100%",
            alignItems: "center",
            justifyContent: "center",
            marginTop: "5rem",
            textAlign: "center",
          }, __self: this, __source: {fileName: _jsxFileName, lineNumber: 110}}

          , React.createElement(Typography, { variant: "body2", __self: this, __source: {fileName: _jsxFileName, lineNumber: 121}}, "Ohne Registrierung fortfahren?"

          )

          , React.createElement(Typography, { variant: "body2", __self: this, __source: {fileName: _jsxFileName, lineNumber: 125}}, "Bitte beachten Sie, dass die Ergebnisse Ihres Kreislaufwirtschafts-Checks in diesem Fall nicht gespeichert werden."


          )
          , React.createElement(Button, {
            style: { padding: "0 0.5rem", marginTop: "0.5rem" },
            color: "primary",
            size: "small",
            onClick: () => {
              app.benchmarks.createBenchmark().then(() => {
                navigate("/check/edit/meta");
              });
            }, __self: this, __source: {fileName: _jsxFileName, lineNumber: 129}}
, "Ohne Account fortfahren"

          )
        )
      )
    )
  );
});

 function _optionalChain(ops) { let lastAccessLHS = undefined; let value = ops[0]; let i = 1; while (i < ops.length) { const op = ops[i]; const fn = ops[i + 1]; i += 2; if ((op === 'optionalAccess' || op === 'optionalCall') && value == null) { return undefined; } if (op === 'access' || op === 'optionalAccess') { lastAccessLHS = value; value = fn(value); } else if (op === 'call' || op === 'optionalCall') { value = fn((...args) => value.call(lastAccessLHS, ...args)); lastAccessLHS = undefined; } } return value; }import { makeAutoObservable } from "mobx";
import { ParseError } from "../helper/ParseError";




/**
 * Small API wrapper around Parse Server without using the Parse JS SDK
 */
export class ParseService {
  

  __init() {this.authError = null}
  __init2() {this.user = null}
  __init3() {this.session = null}

   __init4() {this.host = "https://kreislaufwirtschafts-check.fit.fraunhofer.de/parse"}
  // private host = "http://127.0.0.1:1337/parse";
  // private host = "https://parse-fhms-benchmark.apps.openinc.dev/parse";

   __init5() {this.appKey = "myAppId"}
  // private appKey = "benchmark";

  constructor(app) {;ParseService.prototype.__init.call(this);ParseService.prototype.__init2.call(this);ParseService.prototype.__init3.call(this);ParseService.prototype.__init4.call(this);ParseService.prototype.__init5.call(this);
    makeAutoObservable(this);

    this.app = app;
  }

   async init(cache) {
    this.session = _optionalChain([cache, 'optionalAccess', _ => _.session]);

    if (this.session) {
      try {
        const session = await this.get("/sessions/me");

        const user = await this.get(`/users/${session.user.objectId}`);

        this.user = user;
      } catch (error) {}
    }
  }

   async login(username, password) {
    if (!username) {
      throw new Error("Bitte Nutzernamen angeben");
    }

    if (!password) {
      throw new Error("Bitte Passwort angeben");
    }

    try {
      const session = await this.post("/login", {
        username,
        password,
      });

      this.session = session.sessionToken;

      const user = await this.get(`/users/${session.objectId}`);

      this.authError = null;

      this.user = user;
    } catch (error) {
      this.session = null;
      this.authError = "Login Error";
      this.user = null;

      throw new Error("Login Fehlgeschlagen");
    }
  }

   async logout() {
    this.authError = null;
    this.user = null;
    this.session = null;

    this.app.notifications.info("Erfolgreich ausgeloggt.");
  }

   async updateUserMeta(field, value, id) {
    try {
      const result = await this.app.parse.cc("updateUserMeta", {
        field,
        value,
        id,
      });

      this.app.parse.user[field] = value;
    } catch (error) {
      this.app.notifications.error(error);
    }
  }

   async fetch(
    path,
    info = {},
    errorCount = 0
  ) {
    const headers = Object.assign(
      {},
      info.headers || {},
      {
        "X-Parse-Application-Id": this.appKey,
        "Content-Type": "application/json",
      },
      this.session
        ? {
            "X-Parse-Session-Token": this.session,
          }
        : {}
    );

    const response = await fetch(this.host + path, {
      ...info,
      headers,
    });

    if (response.status >= 400) {
      const json = await response.json();

      if (json.code && json.error) {
        if (json.code === 209 && json.error === "Invalid session token") {
          this.user = null;
          this.session = null;

          if (errorCount === 0) {
            this.app.notifications.info(
              "Deine Session ist abgelaufen, bitte melde dich erneut an."
            );

            return this.fetch(path, info, errorCount + 1);
          }
        }

        throw new ParseError(json.code, json.error);
      } else {
        throw new Error("Bad Status Code");
      }
    }
    return await response.json();
  }

   async get(path) {
    return this.fetch(path, {
      method: "GET",
    });
  }

   async cc(fn, data = {}) {
    return this.post("/functions/" + fn, data);
  }

   async post(path, data) {
    return this.fetch(path, {
      method: "POST",
      body: JSON.stringify(data),
    });
  }

   async put(path, data) {
    return this.fetch(path, {
      method: "PUT",
      body: JSON.stringify(data),
    });
  }

   async delete(path) {
    return this.fetch(path, {
      method: "DELETE",
    });
  }
}

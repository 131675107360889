const _jsxFileName = "/usr/src/app/app/js/components/NotFoundRoute.tsx";import React from "react";
import { observer } from "mobx-react-lite";
import { ErrorMessage } from "./ErrorMessage";

export const NotFoundRoute = observer(function NotFoundRoute() {
  return (
    React.createElement(ErrorMessage, {
      icon: "fa:exclamation-circle",
      title: "404",
      message: "Die Seite konnte nicht gefunden werden"     , __self: this, __source: {fileName: _jsxFileName, lineNumber: 7}}
    )
  );
});

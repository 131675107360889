const _jsxFileName = "/usr/src/app/app/js/components/ContactRoute.tsx";import React, { useEffect } from "react";
import { observer } from "mobx-react-lite";


import {
  Grid,
  makeStyles,
  Typography,
  Card,
  CardContent,
  Button,

} from "@material-ui/core";

import GetAppRoundedIcon from "@material-ui/icons/GetAppRounded";

const useStyles = makeStyles((theme) => ({
  marginBottom: {
    marginBottom: theme.spacing(2),
    textAlign: "left",
  },
  padding: {
    padding: theme.spacing(2),
  },
  logo: {
    marginTop: "0.5rem",
  },
  contact: {
    display: "flex",
    flexDirection: "column",
    flexWrap: "wrap",
    justifyContent: "center",
    height: "100px",
    margin: "0.5rem",
  },
  container: {
    display: "flex",
    flexWrap: "wrap",
    flexDirection: "column",
  },
}));

export const ContactRoute = observer(function ContactRoute() {
  const classes = useStyles();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    React.createElement(Grid, { container: true, justifyContent: "center", spacing: 1, __self: this, __source: {fileName: _jsxFileName, lineNumber: 51}}
      , React.createElement(Grid, { item: true, xs: 12, className: classes.marginBottom, __self: this, __source: {fileName: _jsxFileName, lineNumber: 52}}
        , React.createElement(Typography, {
          variant: "h4",
          component: "h1",
          className: classes.marginBottom, __self: this, __source: {fileName: _jsxFileName, lineNumber: 53}}

          , React.createElement('b', {__self: this, __source: {fileName: _jsxFileName, lineNumber: 58}}, "Ihr Kontakt zu uns"   )
        )
      )

      , React.createElement(Grid, { item: true, xs: 12, className: classes.marginBottom, __self: this, __source: {fileName: _jsxFileName, lineNumber: 62}}
        , React.createElement(Grid, { container: true, spacing: 3, __self: this, __source: {fileName: _jsxFileName, lineNumber: 63}}
          , React.createElement(Grid, { item: true, xs: 12, spacing: 3, __self: this, __source: {fileName: _jsxFileName, lineNumber: 64}}
            , React.createElement(Grid, { container: true, justifyContent: "center", spacing: 1, __self: this, __source: {fileName: _jsxFileName, lineNumber: 65}}
              , React.createElement(Card, { style: { height: "100%", width: "100%" }, __self: this, __source: {fileName: _jsxFileName, lineNumber: 66}}
                , React.createElement(CardContent, { style: { height: "100%", width: "100%" }, __self: this, __source: {fileName: _jsxFileName, lineNumber: 67}}, "Sie haben Fragen oder interessieren sich für die Durchführung der Workshops? "

                    , React.createElement('br', {__self: this, __source: {fileName: _jsxFileName, lineNumber: 69}} ), "Kontaktieren Sie uns gerne:"

                  , React.createElement(Grid, { container: true, spacing: 1, __self: this, __source: {fileName: _jsxFileName, lineNumber: 71}}
                    , React.createElement(Grid, { item: true, xs: 12, sm: 6, __self: this, __source: {fileName: _jsxFileName, lineNumber: 72}}
                      , React.createElement(Typography, {
                        variant: "body2",
                        component: "h2",
                        style: { marginBottom: "1rem", textAlign: "left" }, __self: this, __source: {fileName: _jsxFileName, lineNumber: 73}}

                        , React.createElement('p', {__self: this, __source: {fileName: _jsxFileName, lineNumber: 78}}
                          , React.createElement('b', {__self: this, __source: {fileName: _jsxFileName, lineNumber: 79}}, "Dr. Anja Linnemann"  )
                          , React.createElement('br', {__self: this, __source: {fileName: _jsxFileName, lineNumber: 80}} ), "E-Mail:"
                          , " "
                          , React.createElement('a', {
                            href: "mailto:anja.linnemann@fit.fraunhofer.de",
                            style: {
                              color: "rgb(205, 10, 30)",
                              textDecoration: "none",
                            }, __self: this, __source: {fileName: _jsxFileName, lineNumber: 82}}

                            , React.createElement('i', {__self: this, __source: {fileName: _jsxFileName, lineNumber: 89}}, "anja.linnemann@fit.fraunhofer.de")
                          ), " "
                        )
                      )
                    )
                    , React.createElement(Grid, { item: true, xs: 12, sm: 6, __self: this, __source: {fileName: _jsxFileName, lineNumber: 94}}
                      , React.createElement(Typography, {
                        variant: "body2",
                        component: "h2",
                        style: { marginBottom: "1rem", textAlign: "left" }, __self: this, __source: {fileName: _jsxFileName, lineNumber: 95}}

                        , React.createElement('p', {__self: this, __source: {fileName: _jsxFileName, lineNumber: 100}}
                          , React.createElement('b', {__self: this, __source: {fileName: _jsxFileName, lineNumber: 101}}, "Jonas Barth M. Sc."   )
                          , React.createElement('br', {__self: this, __source: {fileName: _jsxFileName, lineNumber: 102}} ), "E-Mail:"
                          , " "
                          , React.createElement('a', {
                            href: "mailto:j.barth@ptw.tu-darmstadt.de",
                            style: {
                              color: "rgb(205, 10, 30)",
                              textDecoration: "none",
                            }, __self: this, __source: {fileName: _jsxFileName, lineNumber: 104}}

                            , React.createElement('i', {__self: this, __source: {fileName: _jsxFileName, lineNumber: 111}}, "j.barth@ptw.tu-darmstadt.de")
                          ), " "
                        )
                      )
                    )
                    , React.createElement(Grid, { item: true, xs: 12, sm: 6, __self: this, __source: {fileName: _jsxFileName, lineNumber: 116}}
                      , React.createElement(Typography, {
                        variant: "body2",
                        component: "h2",
                        style: { marginBottom: "1rem", textAlign: "left" }, __self: this, __source: {fileName: _jsxFileName, lineNumber: 117}}

                        , React.createElement('p', {__self: this, __source: {fileName: _jsxFileName, lineNumber: 122}}
                          , React.createElement('b', {__self: this, __source: {fileName: _jsxFileName, lineNumber: 123}}, "Lisa Schlömer, Dipl.-Wirt.-Inf. (DH)"   )
                          , React.createElement('br', {__self: this, __source: {fileName: _jsxFileName, lineNumber: 124}} ), "E-Mail:"
                          , " "
                          , React.createElement('a', {
                            href: "mailto:lisa.schloemer@fit.fraunhofer.de",
                            style: {
                              color: "rgb(205, 10, 30)",
                              textDecoration: "none",
                            }, __self: this, __source: {fileName: _jsxFileName, lineNumber: 126}}

                            , React.createElement('i', {__self: this, __source: {fileName: _jsxFileName, lineNumber: 133}}, "lisa.schloemer@fit.fraunhofer.de")
                          ), " "
                        )
                      )
                    )
                    , React.createElement(Grid, { item: true, xs: 12, sm: 6, __self: this, __source: {fileName: _jsxFileName, lineNumber: 138}}
                      , React.createElement(Typography, {
                        variant: "body2",
                        component: "h2",
                        style: { marginBottom: "1rem", textAlign: "left" }, __self: this, __source: {fileName: _jsxFileName, lineNumber: 139}}

                        , React.createElement('p', {__self: this, __source: {fileName: _jsxFileName, lineNumber: 144}}
                          , React.createElement('b', {__self: this, __source: {fileName: _jsxFileName, lineNumber: 145}}, "Enno Lang M. Sc."   )
                          , React.createElement('br', {__self: this, __source: {fileName: _jsxFileName, lineNumber: 146}} ), "E-Mail:"
                          , " "
                          , React.createElement('a', {
                            href: "mailto:e.lang@ptw.tu-darmstadt.de",
                            style: {
                              color: "rgb(205, 10, 30)",
                              textDecoration: "none",
                            }, __self: this, __source: {fileName: _jsxFileName, lineNumber: 148}}

                            , React.createElement('i', {__self: this, __source: {fileName: _jsxFileName, lineNumber: 155}}, "e.lang@ptw.tu-darmstadt.de")
                          ), " "
                        )
                      )
                    )
                  ), "Hier finden Sie alle Unterlagen der Workshops zur eigene Nutzung:"


                  , React.createElement('br', {__self: this, __source: {fileName: _jsxFileName, lineNumber: 163}} )
                  , React.createElement(Button, {
                    variant: "text",
                    color: "primary",
                    size: "small",
                    startIcon: React.createElement(GetAppRoundedIcon, {__self: this, __source: {fileName: _jsxFileName, lineNumber: 168}} ),
                    style: {
                      paddingLeft: 0,
                    },
                    onClick: () => {}, __self: this, __source: {fileName: _jsxFileName, lineNumber: 164}}
, "Download"

                  )
                )
              )
            )
          )
        )
      )

      , React.createElement(Card, { style: { height: "100%", width: "100%" }, __self: this, __source: {fileName: _jsxFileName, lineNumber: 183}}
        , React.createElement(CardContent, { style: { height: "100%", width: "100%" }, __self: this, __source: {fileName: _jsxFileName, lineNumber: 184}}, "Sie sind an weiteren Angeboten der Mittelstand-Digital Zentren interessiert?"


          , React.createElement('br', {__self: this, __source: {fileName: _jsxFileName, lineNumber: 187}} ), " Besuchen Sie die Webauftritte, um Ansprechpartner:innen für Ihre Themen zu finden:"

          , React.createElement(Grid, { container: true, spacing: 3, className: classes.logo, __self: this, __source: {fileName: _jsxFileName, lineNumber: 189}}
            , React.createElement(Grid, { item: true, xs: 12, sm: 6, md: 2, __self: this, __source: {fileName: _jsxFileName, lineNumber: 190}}
              , React.createElement('a', { href: "https://digitalzentrum-darmstadt.de/", target: "_blank", __self: this, __source: {fileName: _jsxFileName, lineNumber: 191}}
                , React.createElement('img', {
                  src: require("../assets/logo/mdz-darmstadt.png"),
                  alt: "Mittelstand-Digitalzentrum Darmstadt" ,
                  height: "100%",
                  width: "100%", __self: this, __source: {fileName: _jsxFileName, lineNumber: 192}}
                )
              )
            )
            , React.createElement(Grid, { item: true, xs: 12, sm: 6, md: 2, __self: this, __source: {fileName: _jsxFileName, lineNumber: 200}}
              , React.createElement('a', {
                href: "https://www.mittelstand-digital-wertnetzwerke.de/",
                target: "_blank", __self: this, __source: {fileName: _jsxFileName, lineNumber: 201}}

                , React.createElement('img', {
                  src: require("../assets/logo/mdz-wnw.png"),
                  alt: "Mittelstand-Digitalzentrum WertNetZWerke" ,
                  height: "100%",
                  width: "100%", __self: this, __source: {fileName: _jsxFileName, lineNumber: 205}}
                )
              )
            )
          )
        )
      )
    )
  );
});

const _jsxFileName = "/usr/src/app/app/js/components/AdminUserRoute.tsx";import React from "react";
import { observer } from "mobx-react-lite";
import { useApp } from "../context";

import { AdminList } from "./AdminList";

export const AdminUserRoute = observer(function AdminUserRoute() {
  const app = useApp();

  return (
    React.createElement(AdminList, {
      title: "Administratoren",
      className: "_User",
      navName: "users",
      rows: app.admin.users, __self: this, __source: {fileName: _jsxFileName, lineNumber: 11}}
    )
  );
});

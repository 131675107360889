const _jsxFileName = "/usr/src/app/app/js/components/SummaryChart.tsx";import { observer } from "mobx-react-lite";
import React from "react";
import { Radar } from "react-chartjs-2";
import {
  Chart as ChartJS,
  LineElement,
  PointElement,
  Tooltip,
  Legend,
  RadialLinearScale,
  Filler,
} from "chart.js";

ChartJS.register(
  LineElement,
  PointElement,
  Tooltip,
  Legend,
  RadialLinearScale,
  Filler
);


















export const SummaryChart = observer(function SummaryChart({
  labels,
  benchmarkList = [],
}) {
  // if (benchmarkList.length === 0) {
  //   benchmarkList.push({
  //     label: "Wählen Sie unten Ihre fertigen Checks",
  //     data: [],
  //     fill: true,
  //     backgroundColor: "rgba(0,0,0,0.1)",
  //     borderColor: "rgba(0,0,0,0.1)",
  //     pointBackgroundColor: "rgba(0,0,0,0.1)",
  //     pointBorderColor: "rgba(0,0,0,0.1)",
  //     pointHoverBackgroundColor: "rgba(0,0,0,0.1)",
  //     pointHoverBorderColor: "rgba(0,0,0,0.1)",
  //   });
  // }

  const data = {
    labels: labels,
    datasets: benchmarkList,
  };

  const options = {
    maintainAspectRatio: false,
    responsive: true,
    elements: {
      line: {
        borderWidth: 3,
      },
    },
    scale: {
      min: 0,
      max: 4,
      step: 1,
      ticks: {
        stepSize: 1,
      },
    },
    plugins: {
      legend: {
        display: true,
        position: "left",
      },
    },
    scales: {
      r: {
        pointLabels: {
          font: {
            size: 16,
          },
        },
      },
    },
  };

  return React.createElement(Radar, { style: { height: "50%" }, data: data, options: options, __self: this, __source: {fileName: _jsxFileName, lineNumber: 96}} );
});

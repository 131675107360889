const _jsxFileName = "/usr/src/app/app/js/components/AdminQuestionRoute.tsx";import React from "react";
import { observer } from "mobx-react-lite";
import { useApp } from "../context";

import { AdminList } from "./AdminList";

export const AdminQuestionRoute = observer(function AdminQuestionRoute() {
  const app = useApp();

  return (
    React.createElement(AdminList, {
      title: "Fragen",
      className: "Question",
      navName: "question",
      rows: app.benchmarks.questions, __self: this, __source: {fileName: _jsxFileName, lineNumber: 11}}
    )
  );
});

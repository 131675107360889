const _jsxFileName = "/usr/src/app/app/js/components/BenchmarkEditRoute.tsx"; function _optionalChain(ops) { let lastAccessLHS = undefined; let value = ops[0]; let i = 1; while (i < ops.length) { const op = ops[i]; const fn = ops[i + 1]; i += 2; if ((op === 'optionalAccess' || op === 'optionalCall') && value == null) { return undefined; } if (op === 'access' || op === 'optionalAccess') { lastAccessLHS = value; value = fn(value); } else if (op === 'call' || op === 'optionalCall') { value = fn((...args) => value.call(lastAccessLHS, ...args)); lastAccessLHS = undefined; } } return value; }import React from "react";
import { observer } from "mobx-react-lite";
import { useApp } from "../context";

import { Navigate, Outlet } from "react-router-dom";

export const BenchmarkEditRoute = observer(function BenchmarkEditRoute() {
  const app = useApp();

  React.useEffect(() => {
    app.benchmarks.fetchCurrentBenchmark();
  }, [_optionalChain([app, 'access', _ => _.benchmarks, 'access', _2 => _2.benchmark, 'optionalAccess', _3 => _3.objectId])]);

  if (!app.benchmarks.benchmark && !app.parse.user) {
    return React.createElement(Navigate, { to: "/check", __self: this, __source: {fileName: _jsxFileName, lineNumber: 15}} );
  }

  return React.createElement(Outlet, {__self: this, __source: {fileName: _jsxFileName, lineNumber: 18}} );
});

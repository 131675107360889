const _jsxFileName = "/usr/src/app/app/js/components/AdminRegionRoute.tsx";import React from "react";
import { observer } from "mobx-react-lite";
import { useApp } from "../context";

import { AdminList } from "./AdminList";

export const AdminRegionRoute = observer(function AdminRegionRoute() {
  const app = useApp();

  return (
    React.createElement(AdminList, {
      title: "Regionen",
      className: "Region",
      navName: "region",
      rows: app.benchmarks.regions, __self: this, __source: {fileName: _jsxFileName, lineNumber: 11}}
    )
  );
});

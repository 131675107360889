const _jsxFileName = "/usr/src/app/app/js/components/BenchmarkSummaryRoute.tsx"; function _optionalChain(ops) { let lastAccessLHS = undefined; let value = ops[0]; let i = 1; while (i < ops.length) { const op = ops[i]; const fn = ops[i + 1]; i += 2; if ((op === 'optionalAccess' || op === 'optionalCall') && value == null) { return undefined; } if (op === 'access' || op === 'optionalAccess') { lastAccessLHS = value; value = fn(value); } else if (op === 'call' || op === 'optionalCall') { value = fn((...args) => value.call(lastAccessLHS, ...args)); lastAccessLHS = undefined; } } return value; }import React, { useEffect, useState } from "react";
import { observer } from "mobx-react-lite";
import { useApp } from "../context";
import {
  Box,
  Button,
  Grid,
  Paper,
  Typography,
  makeStyles,
} from "@material-ui/core";
import AddIcon from "@material-ui/icons/Add";

import DeleteIcon from "@material-ui/icons/Delete";

import { DataGrid, } from "@mui/x-data-grid";
import { useNavigate } from "react-router-dom";

import { SummaryChart } from "./SummaryChart";
import AccessTimeIcon from "@material-ui/icons/AccessTime";
import CheckCircleOutlineIcon from "@material-ui/icons/CheckCircleOutline";
import SubdirectoryArrowRightIcon from "@material-ui/icons/SubdirectoryArrowRight";

const useStyles = makeStyles((theme) => ({
  validationMessage: {
    border: `1px solid ${theme.palette.error.dark}`,
    background: theme.palette.primary.main,
    color: theme.palette.error.contrastText,
    padding: theme.spacing(0.5),
    marginBottom: theme.spacing(2),
    borderRadius: theme.shape.borderRadius,
    fontSize: "0.9em",
  },
  marginBottom: {
    marginBottom: theme.spacing(2),
    textAlign: "justify",
  },
  marginLeft: {
    marginBottom: theme.spacing(2),
    marginLeft: "auto",
    textAlign: "justify",
  },
  padding: {
    padding: theme.spacing(2),
    height: "100%",
  },
  editMetaButton: {
    marginLeft: theme.spacing(1),
  },
  toolTip: {
    paddingLeft: "0.1rem",
    paddingTop: "0.5rem",
  },
  cell: {
    whiteSpace: "normal",
    wordWrap: "break-word",
    lineHeight: "1.5",
    display: "block",
    overflow: "visible",
  },
  // cell: {
  //   whiteSpace: 'nowrap',
  //   overflow: 'auto',
  //   textOverflow: 'ellipsis',
  //   display: 'block',
  // },
}));












export const BenchmarkSummaryRoute = observer(function BenchmarkSummaryRoute() {
  const app = useApp();
  const classes = useStyles();
  const navigate = useNavigate();

  const [userBenchmark, setUserBenchmark] = useState([]);
  const [selectedBenchmark, setSelectedBenchmark] = useState([]);
  const [benchmarkColors, setBenchmarkColors] = useState({});

  const labels = app.benchmarks.categories.map((category) => category.name);

  const findCategoryIdsByLabels = (labels) =>
    labels.map((label) => {
      const foundCategory = app.benchmarks.categories.find(
        (category) => category.name === label
      );
      return _optionalChain([foundCategory, 'optionalAccess', _ => _.objectId]);
    });

  const labelsID = findCategoryIdsByLabels(labels);

  useEffect(() => {
    window.scrollTo(0, 0);

    const fetchData = async () => {
      try {
        const userBenchmark = await app.benchmarks.fetchUserBenchmark();
        setUserBenchmark(userBenchmark);
      } catch (error) {
        console.log("Error when trying to fetch data from database");
      }
    };

    fetchData();
  }, []);

  useEffect(() => {
    const initialColors = {};
    userBenchmark.forEach((benchmark) => {
      if (!initialColors[benchmark.objectId]) {
        initialColors[benchmark.objectId] = getRandomColor();
      }
    });
    setBenchmarkColors(initialColors);
  }, [userBenchmark]);

  function renderIcon(params) {
    const [current, total] = params.value.split(" / ").map(Number);
    const isComplete = current === total;

    const padNumber = (num, size) => {
      let s = num.toString();
      while (s.length < size) s = "0" + s;
      return s;
    };

    const maxLength = Math.max(
      current.toString().length,
      total.toString().length
    );

    return (
      React.createElement('div', { style: { display: "flex", alignItems: "center" }, __self: this, __source: {fileName: _jsxFileName, lineNumber: 142}}
        , React.createElement('span', { style: { marginRight: 10 }, __self: this, __source: {fileName: _jsxFileName, lineNumber: 143}}
          , padNumber(current, maxLength), " / "  , padNumber(total, maxLength)
        )
        , isComplete ? (
          React.createElement(CheckCircleOutlineIcon, { style: { color: "green" }, __self: this, __source: {fileName: _jsxFileName, lineNumber: 147}} )
        ) : (
          React.createElement(AccessTimeIcon, { style: { color: "orange" }, __self: this, __source: {fileName: _jsxFileName, lineNumber: 149}} )
        )
      )
    );
  }

  const rows =
    userBenchmark.length > 0
      ? userBenchmark.map((item) => ({
          id: item.objectId,
          secret: item.secret,
          name: item.alt_name
            ? item.alt_name
            : "Kreislaufwirtschafts-Check-" + item.contact_position,
          createdBy: item.contact_name + " " + item.contact_family_name,
          answer:
            Object.keys(item.answers || {}).length +
            " / " +
            app.benchmarks.questions.length,
          notes: item.notes ? item.notes : "Notizen hinzufügen",
        }))
      : [];

  const columns = [
    {
      field: "name",
      headerName: "KLW-Check-Name",
      flex: 0.3,
      sortable: false,
      editable: false,
      filterable: false,
    },
    {
      field: "createdBy",
      headerName: "Erstellt von",
      flex: 0.2,
      sortable: false,
      editable: false,
      filterable: false,
    },
    {
      field: "answer",
      headerName: "Fortschritt",
      flex: 0.2,
      sortable: false,
      editable: false,
      renderCell: renderIcon,
      filterable: false,
    },
    {
      field: "notes",
      headerName: "Notiz",
      flex: 0.3,
      sortable: false,
      editable: true,
      filterable: false,
      renderCell: (params) => (
        React.createElement('div', { className: classes.cell, __self: this, __source: {fileName: _jsxFileName, lineNumber: 206}}, params.value)
      ),
    },
  ];

  const getCategoryResult = (
    c,
    benchmark
  ) => {
    let res = 0;
    let numberOfQuestions = 0;

    const answersArray = benchmark.answers;

    app.benchmarks.questions
      .filter((q) => _optionalChain([q, 'access', _2 => _2.category, 'optionalAccess', _3 => _3.objectId]) === c)
      .forEach((q) => {
        for (const key in answersArray) {
          if (key === q.objectId) {
            res += answersArray[key] - 1;
            numberOfQuestions += 1;
          }
        }
      });

    return numberOfQuestions > 0
      ? parseFloat((res / numberOfQuestions).toFixed(1))
      : 0;
  };

  function getRandomColor() {
    const r = Math.floor(Math.random() * 256);
    const g = Math.floor(Math.random() * 256);
    const b = Math.floor(Math.random() * 256);
    return {
      backgroundColor: `rgba(${r}, ${g}, ${b}, 0.3)`,
      borderColor: `rgb(${r}, ${g}, ${b})`,
      pointBackgroundColor: `rgb(${r}, ${g}, ${b})`,
      pointHoverBorderColor: `rgb(${r}, ${g}, ${b})`,
    };
  }

  const selectedBenchmarkData = () => {
    const selectedBenchmarkList = [];

    selectedBenchmark.forEach((selectedID) => {
      const selectedRow = userBenchmark.find(
        (item) => item.objectId === selectedID
      );
      if (
        selectedRow &&
        Object.keys(selectedRow.answers).length ===
          app.benchmarks.questions.length
      ) {
        selectedBenchmarkList.push(selectedRow);
      }
    });

    const evaluation = selectedBenchmarkList.map((benchmark) => {
      const data = labelsID.map((l) => getCategoryResult(l, benchmark));

      if (!benchmarkColors[benchmark.objectId]) {
        benchmarkColors[benchmark.objectId] = getRandomColor();
      }

      const colors = benchmarkColors[benchmark.objectId];

      return {
        data,
        fill: true,
        label: benchmark.alt_name
          ? benchmark.alt_name
          : benchmark.contact_position +
            "-" +
            new Intl.DateTimeFormat("de-DE").format(
              new Date(benchmark.createdAt)
            ),
        backgroundColor: colors.backgroundColor,
        borderColor: colors.borderColor,
        pointBackgroundColor: colors.pointBackgroundColor,
        pointBorderColor: "#fff",
        pointHoverBackgroundColor: "#fff",
        pointHoverBorderColor: colors.pointHoverBorderColor,
      };
    });

    return evaluation;
  };

  const handleDelete = () => {
    if (selectedBenchmark.length > 0) {
      const deletePromises = selectedBenchmark.map((selectedID) => {
        const selectedRow = userBenchmark.find(
          (item) => item.objectId === selectedID
        );

        if (selectedRow) {
          return app.benchmarks.deleteBenchmark(
            selectedRow.objectId,
            selectedRow.secret
          );
        }

        return Promise.resolve();
      });

      Promise.all(deletePromises)
        .then(() => {
          const remainingBenchmarks = userBenchmark.filter(
            (item) => !selectedBenchmark.includes(item.objectId)
          );
          setUserBenchmark(remainingBenchmarks);
          setSelectedBenchmark([]);
        })
        .catch((error) => {
          console.error("Error deleting benchmarks:", error);
        });
    }
  };

  const handleContinue = () => {
    if (selectedBenchmark.length === 1) {
      const selectedID = selectedBenchmark[0];
      const selectedRow = userBenchmark.find(
        (item) => item.objectId === selectedID
      );

      if (selectedRow) {
        app.benchmarks
          .resumeBenchmark(selectedRow.objectId, selectedRow.secret)
          .then(() => {
            navigate("/check/edit");
          });
      }
    }
  };

  const handleShowResult = () => {
    if (selectedBenchmark.length === 1) {
      const selectedID = selectedBenchmark[0];
      const selectedRow = userBenchmark.find(
        (item) => item.objectId === selectedID
      );

      if (selectedRow) {
        app.benchmarks
          .resumeBenchmark(selectedRow.objectId, selectedRow.secret)
          .then(() => {
            app.benchmarks.finishCurrentBenchmark();
            navigate("/check/edit/evaluation");
          });
      }
    }
  };

  const processRowUpdate = async (newRow) => {
    const updatedBenchmark = userBenchmark.map((benchmark) => {
      if (benchmark.objectId === newRow.id) {
        return { ...benchmark, notes: newRow.value };
      }
      return benchmark;
    });

    setUserBenchmark(updatedBenchmark);

    const selectedRow = updatedBenchmark.find(
      (item) => item.objectId === newRow.id
    );

    if (selectedRow) {
      try {
        await app.benchmarks.updateBenchmark(
          newRow.field,
          newRow.value,
          selectedRow.objectId,
          selectedRow.secret
        );
        app.notifications.success("Eingabe gespeichert");
      } catch (error) {
        app.notifications.error("hoac la loi o day");
      }
    }

    return newRow;
  };

  const CustomNoRowsOverlay = () => {
    return (
      React.createElement(Box, {
        sx: {
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          height: "100%",
        }, __self: this, __source: {fileName: _jsxFileName, lineNumber: 394}}

        , React.createElement(Typography, { variant: "h6", color: "textSecondary", __self: this, __source: {fileName: _jsxFileName, lineNumber: 402}}, "noch keine KLW-Checks durchgeführt"

        )
      )
    );
  };

  return (
    React.createElement(Grid, { container: true, justifyContent: "center", spacing: 1, __self: this, __source: {fileName: _jsxFileName, lineNumber: 410}}
      , React.createElement(Grid, { item: true, xs: 12, className: classes.marginBottom, __self: this, __source: {fileName: _jsxFileName, lineNumber: 411}}
        , React.createElement(Typography, {
          variant: "h4",
          component: "h1",
          className: classes.marginBottom, __self: this, __source: {fileName: _jsxFileName, lineNumber: 412}}

          , React.createElement('b', {__self: this, __source: {fileName: _jsxFileName, lineNumber: 417}}, "Historie über Ihre Kreislaufwirtschafts-Checks"   )
        )

        , React.createElement(Typography, { variant: "body1", className: classes.marginBottom, __self: this, __source: {fileName: _jsxFileName, lineNumber: 420}}, "In der folgenden Tabelle sehen Sie alle begonnenen und beendeten KLW-Checks Ihres Unternehmensaccounts. "

             , React.createElement('br', {__self: this, __source: {fileName: _jsxFileName, lineNumber: 422}} )
          , React.createElement('br', {__self: this, __source: {fileName: _jsxFileName, lineNumber: 423}} ), "Sie können von hier aus zur Übersicht oder Auswertung eines KLW-Checks gelangen oder ihn löschen. Wählen Sie dazu einen oder mehrere KLW-Checks aus, um die jeweils verfügbaren Funktionen zu aktivieren."


                  , " "
          , React.createElement('br', {__self: this, __source: {fileName: _jsxFileName, lineNumber: 427}} )
          , React.createElement('br', {__self: this, __source: {fileName: _jsxFileName, lineNumber: 428}} ), "Alle ausgewählten (vollständigen"
            , " "
          , React.createElement(CheckCircleOutlineIcon, {
            fontSize: "small",
            style: { color: "green", verticalAlign: "middle" }, __self: this, __source: {fileName: _jsxFileName, lineNumber: 430}}
          ), ") KLW-Checks werden zudem unten im Netzdiagramm angezeigt."

        )
      )

      , React.createElement(Grid, { item: true, xs: 12, className: classes.marginBottom, __self: this, __source: {fileName: _jsxFileName, lineNumber: 438}}
        , React.createElement(Paper, { className: classes.padding, __self: this, __source: {fileName: _jsxFileName, lineNumber: 439}}
          , React.createElement(Typography, { variant: "body1", className: classes.marginBottom, __self: this, __source: {fileName: _jsxFileName, lineNumber: 440}}
            , React.createElement('div', { style: { height: 400, width: "100%", overflow: "hidden" }, __self: this, __source: {fileName: _jsxFileName, lineNumber: 441}}
              , React.createElement(DataGrid, {
                columns: columns,
                rows: rows,
                checkboxSelection: true,
                disableColumnSelector: true,
                editMode: "cell",
                hideFooter: true,
                disableSelectionOnClick: true,
                onSelectionModelChange: (newSelectedBenchmark) => {
                  setSelectedBenchmark(newSelectedBenchmark );
                },
                selectionModel: selectedBenchmark,
                onCellEditCommit: processRowUpdate,
                components: {
                  NoRowsOverlay: CustomNoRowsOverlay,
                }, __self: this, __source: {fileName: _jsxFileName, lineNumber: 442}}
              )
            )
          )

          , React.createElement(Typography, {
            variant: "body1",
            style: {
              display: "flex",
              flexDirection: "row",
              gap: "1rem",
              paddingLeft: "1rem",
            }, __self: this, __source: {fileName: _jsxFileName, lineNumber: 462}}

            , React.createElement(SubdirectoryArrowRightIcon, { fontSize: "large", __self: this, __source: {fileName: _jsxFileName, lineNumber: 471}} )

            , React.createElement(Button, {
              variant: selectedBenchmark.length == 1 ? "contained" : "outlined",
              color: "primary",
              size: "large",
              className: classes.marginBottom,
              onClick: handleContinue,
              disabled: selectedBenchmark.length !== 1, __self: this, __source: {fileName: _jsxFileName, lineNumber: 473}}
, "Zur übersicht"

            )

            , React.createElement(Button, {
              variant: "outlined",
              color: "primary",
              size: "large",
              className: classes.marginBottom,
              onClick: handleShowResult,
              disabled: 
                selectedBenchmark.length !== 1 ||
                !_optionalChain([userBenchmark, 'access', _4 => _4.find, 'call', _5 => _5((b) => b.objectId === selectedBenchmark[0])
, 'optionalAccess', _6 => _6.isFinished])
              , __self: this, __source: {fileName: _jsxFileName, lineNumber: 484}}
, "Zur Auswertung"

            )

            , React.createElement(Button, {
              variant: "outlined",
              color: "primary",
              size: "large",
              className: classes.marginBottom,
              startIcon: React.createElement(DeleteIcon, {__self: this, __source: {fileName: _jsxFileName, lineNumber: 504}} ),
              onClick: handleDelete,
              disabled: selectedBenchmark.length === 0, __self: this, __source: {fileName: _jsxFileName, lineNumber: 499}}
, "Löschen"

            )

            , React.createElement(Button, {
              variant: "outlined",
              color: "primary",
              size: "large",
              className: classes.marginLeft,
              startIcon: React.createElement(AddIcon, {__self: this, __source: {fileName: _jsxFileName, lineNumber: 516}} ),
              onClick: () => {
                if (
                  _optionalChain([app, 'access', _7 => _7.parse, 'access', _8 => _8.user, 'optionalAccess', _9 => _9.companyBranch]) &&
                  _optionalChain([app, 'access', _10 => _10.parse, 'access', _11 => _11.user, 'optionalAccess', _12 => _12.companySize]) &&
                  _optionalChain([app, 'access', _13 => _13.parse, 'access', _14 => _14.user, 'optionalAccess', _15 => _15.companyRevenue])
                ) {
                  app.benchmarks
                    .createBenchmarkUser(
                      _optionalChain([app, 'access', _16 => _16.parse, 'access', _17 => _17.user, 'optionalAccess', _18 => _18.companyBranch]),
                      _optionalChain([app, 'access', _19 => _19.parse, 'access', _20 => _20.user, 'optionalAccess', _21 => _21.companySize]),
                      _optionalChain([app, 'access', _22 => _22.parse, 'access', _23 => _23.user, 'optionalAccess', _24 => _24.companyRevenue])
                    )
                    .then(() => {
                      navigate("/check/edit/finish");
                    });
                } else {
                  app.benchmarks.createBenchmark().then(() => {
                    navigate("/check/edit/meta");
                  });
                }
              }, __self: this, __source: {fileName: _jsxFileName, lineNumber: 511}}
, "Neuen Check starten"

            )
          )
          , React.createElement(Typography, {
            variant: "body2",
            style: { paddingLeft: "4rem", fontStyle: "italic" }, __self: this, __source: {fileName: _jsxFileName, lineNumber: 542}}
, "Wählen Sie einen oder mehrere Checks aus, um die verfügbaren Funktionen zu aktivieren"


          )
        )
      )

      , React.createElement(Grid, { item: true, xs: 12, __self: this, __source: {fileName: _jsxFileName, lineNumber: 552}}
        , React.createElement(Paper, { className: classes.padding, __self: this, __source: {fileName: _jsxFileName, lineNumber: 553}}
          , React.createElement(Typography, {
            variant: "h5",
            component: "h1",
            className: classes.marginBottom, __self: this, __source: {fileName: _jsxFileName, lineNumber: 554}}

            , React.createElement('b', {__self: this, __source: {fileName: _jsxFileName, lineNumber: 559}}, "Ausgewählte KLW-Checks im Überblick"   )
          )

          , React.createElement('i', {__self: this, __source: {fileName: _jsxFileName, lineNumber: 562}}
            , selectedBenchmark.length === 0
              ? "Kein Check ausgewählt."
              : "Über die farbigen Schaltflächen können Sie die Graphen aus- und wieder einblenden."
          )

          , React.createElement('div', { style: { height: 500, width: 1000, margin: "auto" }, __self: this, __source: {fileName: _jsxFileName, lineNumber: 568}}
            , React.createElement(SummaryChart, {
              labels: labels,
              benchmarkList: selectedBenchmarkData(), __self: this, __source: {fileName: _jsxFileName, lineNumber: 569}}
            )
          )
        )
      )
    )
  );
});

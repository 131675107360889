const _jsxFileName = "/usr/src/app/app/js/components/UserRegisterRoute.tsx";import React from "react";
import { observer } from "mobx-react-lite";
import { useApp } from "../context";

import { Button, TextField, Typography } from "@material-ui/core";

import { useNavigate } from "react-router-dom";

export const UserRegisterRoute = observer(function UserRegisterRoute() {
  const app = useApp();
  const navigate = useNavigate();

  const [state, setState] = React.useState({
    companyName: "",
    email: "",
    password: "",
  });

  return (
    React.createElement('div', { style: { width: 600, margin: "0 auto" }, __self: this, __source: {fileName: _jsxFileName, lineNumber: 20}}
      , React.createElement(Typography, {
        component: "h1",
        variant: "h4",
        align: "center",
        style: { marginBottom: "1rem" }, __self: this, __source: {fileName: _jsxFileName, lineNumber: 21}}
, "Erstellen Sie Ihren kostenfreien Unternehmensaccount"

      )

      , React.createElement(Typography, {
        component: "h1",
        variant: "body1",
        align: "center",
        style: { marginBottom: "1rem" }, __self: this, __source: {fileName: _jsxFileName, lineNumber: 30}}
, "Mit einem Unternehmensaccount können Ihre Eingaben jederzeit gespeichert bleiben."


      )

      , React.createElement(Typography, {
        component: "h1",
        variant: "body1",
        align: "center",
        style: { marginBottom: "1rem" }, __self: this, __source: {fileName: _jsxFileName, lineNumber: 40}}

        , React.createElement('u', {__self: this, __source: {fileName: _jsxFileName, lineNumber: 46}}, "Beachten Sie" ), ": Diese Login-Daten gelten unternehmensweit. Verwenden Sie daher "
           , React.createElement('b', {__self: this, __source: {fileName: _jsxFileName, lineNumber: 47}}, "ein allgemeines Passwort"  ), ". Die anzugebene Email-Adresse wird ausschließlich für den Zweck der Anmeldung verwendet - es werden keine Newsletter o. ä. gesendet."


      )

      , React.createElement('div', { style: { width: 400, margin: "0 auto" }, __self: this, __source: {fileName: _jsxFileName, lineNumber: 52}}
        , React.createElement('form', {
          style: {
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          },
          noValidate: true,
          onSubmit: function handleSubmit(event) {
            event.preventDefault();

            app.admin
              .createUser(state.companyName, state.email, state.password)
              .then(
                () => {
                  app.notifications.success("User erstellt");
                  navigate("/userLogin");
                },
                (error) => {
                  app.notifications.error(error.message);
                }
              );
          }, __self: this, __source: {fileName: _jsxFileName, lineNumber: 53}}

          , React.createElement(TextField, {
            variant: "outlined",
            margin: "normal",
            required: true,
            fullWidth: true,
            id: "companyName",
            label: "Unternehmensname",
            name: "companyName",
            autoComplete: "companyName",
            autoFocus: true,
            onChange: (e) => {
              setState((cur) => ({ ...cur, companyName: e.target.value }));
            }, __self: this, __source: {fileName: _jsxFileName, lineNumber: 76}}
          )
          , React.createElement(TextField, {
            variant: "outlined",
            margin: "normal",
            required: true,
            fullWidth: true,
            id: "email",
            label: "E-Mail Adresse" ,
            name: "email",
            autoComplete: "email",
            onChange: (e) => {
              setState((cur) => ({ ...cur, email: e.target.value }));
            }, __self: this, __source: {fileName: _jsxFileName, lineNumber: 90}}
          )
          , React.createElement(TextField, {
            variant: "outlined",
            margin: "normal",
            required: true,
            fullWidth: true,
            name: "password",
            label: "Passwort",
            type: "password",
            id: "password",
            autoComplete: "current-password",
            onChange: (e) => {
              setState((cur) => ({ ...cur, password: e.target.value }));
            }, __self: this, __source: {fileName: _jsxFileName, lineNumber: 103}}
          )
          , React.createElement(Button, { type: "submit", fullWidth: true, variant: "contained", color: "primary", __self: this, __source: {fileName: _jsxFileName, lineNumber: 117}}, "Account erstellen"

          )

          , React.createElement('div', {
            style: {
              display: "flex",
              flexDirection: "row",
              width: "100%",
              alignItems: "center",
              justifyContent: "center",
              marginTop: "1rem",
            }, __self: this, __source: {fileName: _jsxFileName, lineNumber: 121}}

            , React.createElement(Typography, { variant: "body2", __self: this, __source: {fileName: _jsxFileName, lineNumber: 131}}, "Account bereits vorhanden?"  )
            , React.createElement(Button, {
              style: { padding: "0 0.5rem" },
              color: "primary",
              size: "small",
              onClick: () => {
                navigate("/userLogin");
              }, __self: this, __source: {fileName: _jsxFileName, lineNumber: 132}}
, "Zum Login"

            )
          )

          , React.createElement('div', {
            style: {
              display: "flex",
              flexDirection: "column",
              width: "100%",
              alignItems: "center",
              justifyContent: "center",
              marginTop: "5rem",
              textAlign: "center",
            }, __self: this, __source: {fileName: _jsxFileName, lineNumber: 144}}

            , React.createElement(Typography, { variant: "body2", __self: this, __source: {fileName: _jsxFileName, lineNumber: 155}}, "Ohne Registrierung fortfahren?"

            )

            , React.createElement(Typography, { variant: "body2", __self: this, __source: {fileName: _jsxFileName, lineNumber: 159}}, "Bitte beachten Sie, dass die Ergebnisse Ihres Kreislaufwirtschafts-Checks in diesem Fall nicht gespeichert werden."



            )
            , React.createElement(Button, {
              style: { padding: "0 0.5rem", marginTop: "0.5rem" },
              color: "primary",
              size: "small",
              onClick: () => {
                app.benchmarks.createBenchmark().then(() => {
                  navigate("/check/edit/meta");
                });
              }, __self: this, __source: {fileName: _jsxFileName, lineNumber: 164}}
, "Ohne Account fortfahren"

            )
          )
        )
      )
    )
  );
});

 function _nullishCoalesce(lhs, rhsFn) { if (lhs != null) { return lhs; } else { return rhsFn(); } } function _optionalChain(ops) { let lastAccessLHS = undefined; let value = ops[0]; let i = 1; while (i < ops.length) { const op = ops[i]; const fn = ops[i + 1]; i += 2; if ((op === 'optionalAccess' || op === 'optionalCall') && value == null) { return undefined; } if (op === 'access' || op === 'optionalAccess') { lastAccessLHS = value; value = fn(value); } else if (op === 'call' || op === 'optionalCall') { value = fn((...args) => value.call(lastAccessLHS, ...args)); lastAccessLHS = undefined; } } return value; }import { makeAutoObservable } from "mobx";













export class BenchmarkService {
  

  __init() {this.currentBenchmark = null}
  __init2() {this.benchmarks = {}}

  __init3() {this.categories = []}
  __init4() {this.questions = []}
  __init5() {this.regions = []}
  __init6() {this.industries = []}
  __init7() {this.dimensions = []}
  __init8() {this.measures = []}

  constructor(app) {;BenchmarkService.prototype.__init.call(this);BenchmarkService.prototype.__init2.call(this);BenchmarkService.prototype.__init3.call(this);BenchmarkService.prototype.__init4.call(this);BenchmarkService.prototype.__init5.call(this);BenchmarkService.prototype.__init6.call(this);BenchmarkService.prototype.__init7.call(this);BenchmarkService.prototype.__init8.call(this);
    makeAutoObservable(this);

    this.app = app;
  }

   get benchmark() {
    if (!this.currentBenchmark) {
      return null;
    }

    return this.benchmarks[this.currentBenchmark];
  }

   get benchmarkUrl() {
    if (!this.benchmark) {
      return "";
    }

    const host = window.location.origin;
    const id = this.benchmark.objectId;
    const secret = this.benchmark.secret;

    return `${host}/check/resume/${id}/${secret}`;
  }

   get benchmarkResultUrl() {
    if (!this.benchmark) {
      return "";
    }

    const host = window.location.origin;
    const id = this.benchmark.objectId;
    const secret = this.benchmark.secret;

    return `${host}/check/result/${id}/${secret}`;
  }

   get metaAsText() {
    if (!this.benchmark) {
      return "";
    }

    return [
      // add noble text
      this.industriesOptions[_optionalChain([this, 'access', _ => _.benchmark, 'access', _2 => _2.industry, 'optionalAccess', _3 => _3.objectId]) || ""],
      // this.regionsOptions[this.benchmark.region?.objectId || ""],
      this.sizeOptions[this.benchmark.company_size || ""],
      this.revenueOptions[this.benchmark.company_revenue || ""],
    ].join(", ");
  }

   get industriesOptions() {
    return {
      "": "Bitte Branche ausfüllen - Zukünftig können Sie Ihren Reifegrad innerhalb Ihrer Branche vergleichen.",
      ...Object.fromEntries(this.industries.map((x) => [x.objectId, x.name])),
      other: "Sonstiges",
    };
  }

   get regionsOptions() {
    return {
      "": "Bitte Region ausfüllen",
      ...Object.fromEntries(this.regions.map((x) => [x.objectId, x.name])),
      // other: "Sonstiges",
    };
  }

   get sizeOptions() {
    return {
      "": "Bitte Mitarbeiter ausfüllen",
      "<9": "0 - 9 Mitarbeiter",
      "10 - 49": "10 - 49 Mitarbeiter",
      "50-249": "50-249 Mitarbeiter",
      ">249": "Mehr als 249 Mitarbeiter",
    };
  }

   get revenueOptions() {
    return {
      "": "Bitte Umsatz ausfüllen",
      "<1000000": "Weniger als 1 Mio. € Umsatz",
      "1000000-10000000": "1 Mio. € - 10 Mio. € Umsatz",
      "10000000-50000000": "10 Mio. € - 50 Mio. € Umsatz",
      ">50000000": "Mehr als 50 Mio. € Umsatz",
    };
  }

   get answers() {
    if (!_optionalChain([this, 'access', _4 => _4.benchmark, 'optionalAccess', _5 => _5.answers])) {
      return {};
    }

    return this.benchmark.answers;
  }

   validateCurrentBenchmark() {
    if (!this.benchmark) {
      return {
        valid: false,
        field: "benchmark",
      };
    }

    if (!this.benchmark.industry && !this.app.parse.user) {
      return {
        valid: false,
        field: "meta",
      };
    }

    // if (!this.benchmark.region) {
    //   return {
    //     valid: false,
    //     field: "meta",
    //   };
    // }

    if (!this.benchmark.company_revenue && !this.app.parse.user) {
      return {
        valid: false,
        field: "meta",
      };
    }

    if (!this.benchmark.company_size && !this.app.parse.user) {
      return {
        valid: false,
        field: "meta",
      };
    }

    const answers = Object.values(this.answers).filter(Boolean).length;

    if (answers < this.questions.length) {
      return {
        valid: false,
        field: "answers",
      };
    }

    return {
      valid: true,
    };
  }

   validateCurrentEmail(email) {
    email = _nullishCoalesce(email, () => ( _optionalChain([this, 'access', _6 => _6.benchmark, 'optionalAccess', _7 => _7.contact_email])));

    return (
      !!email &&
      email.includes("@") &&
      !email.startsWith("@") &&
      !email.endsWith("@")
    );
  }

   async init(cache) {
    Object.assign(this, cache);

    const categories = (
      await this.app.parse.get("/classes/Category?order=order")
    ).results;

    const questions = (
      await this.app.parse.get("/classes/Question?order=order")
    ).results;

    const industries = (
      await this.app.parse.get("/classes/Industry?order=order")
    ).results;

    const regions = (
      await this.app.parse.get("/classes/Region?order=order")
    ).results;

    const dimensions = (
      await this.app.parse.get("/classes/Dimension?order=order")
    ).results;

    const measures = (
      await this.app.parse.get("/classes/Measure?order=order")
    ).results;

    if (this.app.admin.isAdmin) {
      this.categories = categories;
      this.questions = questions;
    } else {
      // Remove all categories without a question
      this.categories = categories.filter(
        (category) =>
          !!questions.find(
            (question) => _optionalChain([question, 'access', _8 => _8.category, 'optionalAccess', _9 => _9.objectId]) === category.objectId
          )
      );

      // Remove all questions without a category
      this.questions = questions.filter(
        (question) =>
          _optionalChain([question, 'access', _10 => _10.category, 'optionalAccess', _11 => _11.objectId]) &&
          !!categories.find(
            (category) => category.objectId === _optionalChain([question, 'access', _12 => _12.category, 'optionalAccess', _13 => _13.objectId])
          )
      );
    }

    this.dimensions = dimensions;
    this.measures = measures;
    this.industries = industries;
    this.regions = regions;
  }

   async resumeBenchmark(id, secret) {
    try {
      const { result: benchmark } = await this.app.parse.cc("benchmark-fetch", {
        id: id,
        secret: secret,
      });

      this.benchmarks[benchmark.objectId] = benchmark;

      this.currentBenchmark = benchmark.objectId;

      this.app.notifications.success("Benchmark geladen");
    } catch (error) {
      this.app.notifications.error(
        "Benchmark wurde nicht gefunden oder der Zugang ist abgelaufen"
      );
    }
  }

   async createBenchmark() {
    try {
      const { result: benchmark } = await this.app.parse.cc("benchmark-create");

      this.benchmarks[benchmark.objectId] = benchmark;

      this.currentBenchmark = benchmark.objectId;

      this.app.notifications.success("Benchmark erstellt");
    } catch (error) {
      this.app.notifications.error("Benchmark konnte nicht erstellt werden");
    }
  }

   async createBenchmarkUser(branch, size, revenue) {
    try {
      const { result: benchmark } = await this.app.parse.cc(
        "benchmark-create",
        {
          branch: branch,
          size: size,
          revenue: revenue,
        }
      );

      this.benchmarks[benchmark.objectId] = benchmark;

      this.currentBenchmark = benchmark.objectId;

      this.app.notifications.success("Benchmark erstellt");
    } catch (error) {
      this.app.notifications.error("Benchmark konnte nicht erstellt werden");
    }
  }

   async fetchCurrentBenchmark() {
    try {
      if (!this.benchmark) {
        return;
      }

      const { result } = await this.app.parse.cc("benchmark-fetch", {
        id: this.benchmark.objectId,
        secret: this.benchmark.secret,
      });

      Object.assign(this.benchmark, result);

      this.app.notifications.success("Benchmark synchronisiert");
    } catch (error) {
      this.app.notifications.error(
        "Benchmark konnte nicht synchronisiert werden"
      );
    }
  }

   async fetchAllBenchmark() {
    try {
      if (!this.benchmark) {
        return;
      }

      const { result } = await this.app.parse.cc("benchmark-fetch-all", {
        id: this.benchmark.objectId,
        secret: this.benchmark.secret,
      });

      this.app.notifications.success("Benchmarks fetched");
      return result;
    } catch (error) {
      this.app.notifications.error("Benchmarks not fetched correctly");
    }
  }

   async fetchUserBenchmark() {
    if (!this.app.parse.user) {
      return;
    }

    try {
      const { result } = await this.app.parse.cc(
        "benchmark-fetch-from-user",
        {}
      );

      const fetchedBenchmarkIds = new Set(
        result.map((benchmark) => benchmark.objectId)
      );

      for (const key in this.benchmarks) {
        if (!fetchedBenchmarkIds.has(key)) {
          delete this.benchmarks[key];
        }
      }

      result.forEach((benchmark) => {
        this.benchmarks[benchmark.objectId] = benchmark;
      });

      this.app.notifications.success("Benchmarks fetched");
      return result;
    } catch (error) {
      this.app.notifications.error("Benchmarks not fetched correctly");
    }
  }

   async fetchAllBenchmarkSameBranch() {
    try {
      if (!this.benchmark) {
        return;
      }

      const { result } = await this.app.parse.cc(
        "benchmark-fetch-all-same-branch",
        {
          id: this.benchmark.objectId,
          secret: this.benchmark.secret,
        }
      );

      this.app.notifications.success("Benchmarks fetched");
      return result;
    } catch (error) {
      this.app.notifications.error("Benchmarks not fetched correctly");
    }
  }

   async updateCurrentBenchmark(field, value) {
    try {
      if (!this.benchmark) {
        return;
      }

      const { result } = await this.app.parse.cc("benchmark-update", {
        id: this.benchmark.objectId,
        secret: this.benchmark.secret,
        field,
        value,
      });

      const changed = result[field] != (_optionalChain([this, 'access', _14 => _14.benchmark, 'optionalAccess', _15 => _15[field]]) );

      Object.assign(this.benchmark, result);

      if (changed) {
        this.app.notifications.success("Eingabe gespeichert");
      }
    } catch (error) {
      this.app.notifications.error("Eingabe konnte nicht gespeichert werden");
    }
  }

   async updateBenchmark(
    field,
    value,
    objectId,
    secret
  ) {
    try {
      const { result } = await this.app.parse.cc("benchmark-update", {
        id: objectId,
        secret: secret,
        field,
        value,
      });

      // Get an array of benchmark objects
      const benchmarkArray = Object.values(this.app.benchmarks.benchmarks);
      console.log(benchmarkArray);

      // Find the benchmark with the matching objectId and secret
      const benchmarkToUpdate = benchmarkArray.find(
        (benchmark) =>
          benchmark.objectId === objectId && benchmark.secret === secret
      );

      if (benchmarkToUpdate) {
        const changed = result[field] != (benchmarkToUpdate[field] );
        Object.assign(benchmarkToUpdate, result);

        if (changed) {
          this.app.notifications.success("Eingabe gespeichert");
        }
      } else {
        this.app.notifications.error("Benchmark nicht gefunden");
      }
    } catch (error) {
      this.app.notifications.error("loi o day");
    }
  }

   async updateAnswerCurrentBenchmark(
    questionId,
    value
  ) {
    try {
      if (!this.benchmark) {
        return;
      }

      const { result } = await this.app.parse.cc("benchmark-update", {
        id: this.benchmark.objectId,
        secret: this.benchmark.secret,
        field: "answers",
        key: questionId,
        value,
      });

      Object.assign(this.benchmark, result);

      this.app.notifications.success("Antwort gespeichert");
    } catch (error) {
      this.app.notifications.error("Antworte konnte nicht gespeichert werden");
    }
  }

   async finishCurrentBenchmark() {
    try {
      if (!this.benchmark || this.benchmark.isFinished) {
        return;
      }

      await this.app.parse.cc("benchmark-finish", {
        id: this.benchmark.objectId,
        secret: this.benchmark.secret,
      });

      this.app.notifications.success("Benchmark abgeschlossen");
    } catch (error) {
      this.app.notifications.error("Benchmark kann nicht abgeschlossen werden");
    }
  }

   async deleteBenchmark(id, secret) {
    try {
      await this.app.parse.cc("benchmark-delete", {
        id: id,
        secret: secret,
      });

      this.currentBenchmark = null;
      delete this.benchmarks[id];

      this.app.notifications.success("Benchmark gelöscht");
    } catch (error) {
      this.app.notifications.error("Benchmark kann nicht gelöscht werden");
    }
  }
}

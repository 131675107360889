const _jsxFileName = "/usr/src/app/app/js/components/BenchmarkEditMetaRoute.tsx"; function _optionalChain(ops) { let lastAccessLHS = undefined; let value = ops[0]; let i = 1; while (i < ops.length) { const op = ops[i]; const fn = ops[i + 1]; i += 2; if ((op === 'optionalAccess' || op === 'optionalCall') && value == null) { return undefined; } if (op === 'access' || op === 'optionalAccess') { lastAccessLHS = value; value = fn(value); } else if (op === 'call' || op === 'optionalCall') { value = fn((...args) => value.call(lastAccessLHS, ...args)); lastAccessLHS = undefined; } } return value; }import React from "react";
import { observer } from "mobx-react-lite";
import { useApp } from "../context";

import { useNavigate } from "react-router-dom";
import {
  Button,
  FormControl,
  Grid,
  InputLabel,
  makeStyles,
  MenuItem,
  Paper,
  Select,
  Typography,
} from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  padding: {
    padding: theme.spacing(2),
  },
  formControl: {
    width: "100%",
    marginBottom: 20,
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
}));

export const BenchmarkEditMetaRoute = observer(
  function BenchmarkEditMetaRoute() {
    const app = useApp();
    const classes = useStyles();
    const navigate = useNavigate();

    return (
      React.createElement(Paper, { className: classes.padding, __self: this, __source: {fileName: _jsxFileName, lineNumber: 38}}
        , React.createElement(Grid, { container: true, justifyContent: "center", spacing: 1, __self: this, __source: {fileName: _jsxFileName, lineNumber: 39}}
          , React.createElement(Grid, { item: true, xs: 12, __self: this, __source: {fileName: _jsxFileName, lineNumber: 40}}
            , React.createElement('div', { className: classes.formControl, __self: this, __source: {fileName: _jsxFileName, lineNumber: 41}}
              , React.createElement(Typography, { component: "h1", variant: "h5", __self: this, __source: {fileName: _jsxFileName, lineNumber: 42}}, "Informationen zu Ihrem Unternehmen"

              )

              , React.createElement(Typography, { variant: "body1", __self: this, __source: {fileName: _jsxFileName, lineNumber: 46}}, "Bitte beantworten Sie folgende Fragen über Ihr Unternehmen."

              )
            )
          )
          , React.createElement(Grid, { item: true, xs: 12, __self: this, __source: {fileName: _jsxFileName, lineNumber: 51}}
            , React.createElement(Typography, { variant: "body1", className: classes.formControl, __self: this, __source: {fileName: _jsxFileName, lineNumber: 52}}, "In welcher Branche ist Ihr Unternehmen vordergründig tätig?"

            )

            , React.createElement(FormControl, { variant: "outlined", className: classes.formControl, __self: this, __source: {fileName: _jsxFileName, lineNumber: 56}}
              , React.createElement(InputLabel, {__self: this, __source: {fileName: _jsxFileName, lineNumber: 57}}, "Branche")
              , React.createElement(Select, {
                value: _optionalChain([app, 'access', _ => _.benchmarks, 'access', _2 => _2.benchmark, 'optionalAccess', _3 => _3.industry, 'optionalAccess', _4 => _4.objectId]) || "",
                onChange: (e) => {
                  app.benchmarks.updateCurrentBenchmark(
                    "industry",
                    e.target.value 
                  );
                  if (app.parse.user) {
                    app.parse.updateUserMeta(
                      "companyBranch",
                      e.target.value ,
                      _optionalChain([app, 'access', _5 => _5.parse, 'access', _6 => _6.user, 'optionalAccess', _7 => _7.objectId])
                    );
                  }
                },
                label: "Branche", __self: this, __source: {fileName: _jsxFileName, lineNumber: 58}}

                , Object.entries(app.benchmarks.industriesOptions).map(
                  ([key, label]) => (
                    React.createElement(MenuItem, { key: key, value: key, __self: this, __source: {fileName: _jsxFileName, lineNumber: 77}}
                      , key === "" ? React.createElement('em', {__self: this, __source: {fileName: _jsxFileName, lineNumber: 78}}, label) : label
                    )
                  )
                )
              )
            )

            , React.createElement(Typography, { variant: "body1", className: classes.formControl, __self: this, __source: {fileName: _jsxFileName, lineNumber: 85}}, "Wie viele festangestellte Mitarbeiter sind Ihrer Kenntnis nach in Ihrem Unternehmen tätig? (Stand heute)"


            )

            , React.createElement(FormControl, { variant: "outlined", className: classes.formControl, __self: this, __source: {fileName: _jsxFileName, lineNumber: 90}}
              , React.createElement(InputLabel, {__self: this, __source: {fileName: _jsxFileName, lineNumber: 91}}, "Mitarbeiter")
              , React.createElement(Select, {
                value: _optionalChain([app, 'access', _8 => _8.benchmarks, 'access', _9 => _9.benchmark, 'optionalAccess', _10 => _10.company_size]) || "",
                onChange: (e) => {
                  app.benchmarks.updateCurrentBenchmark(
                    "company_size",
                    e.target.value 
                  );
                  if (app.parse.user) {
                    app.parse.updateUserMeta(
                      "companySize",
                      e.target.value ,
                      _optionalChain([app, 'access', _11 => _11.parse, 'access', _12 => _12.user, 'optionalAccess', _13 => _13.objectId])
                    );
                  }
                },
                label: "Mitarbeiter", __self: this, __source: {fileName: _jsxFileName, lineNumber: 92}}

                , Object.entries(app.benchmarks.sizeOptions).map(
                  ([key, label]) => (
                    React.createElement(MenuItem, { key: key, value: key, __self: this, __source: {fileName: _jsxFileName, lineNumber: 111}}
                      , key === "" ? React.createElement('em', {__self: this, __source: {fileName: _jsxFileName, lineNumber: 112}}, label) : label
                    )
                  )
                )
              )
            )

            , React.createElement(Typography, { variant: "body1", className: classes.formControl, __self: this, __source: {fileName: _jsxFileName, lineNumber: 119}}, "Wie hoch war der Umsatz Ihres Unternehmens Ihrer Kenntnis nach im letzten Wirtschaftsjahr?"


            )

            , React.createElement(FormControl, { variant: "outlined", className: classes.formControl, __self: this, __source: {fileName: _jsxFileName, lineNumber: 124}}
              , React.createElement(InputLabel, {__self: this, __source: {fileName: _jsxFileName, lineNumber: 125}}, "Umsatz")
              , React.createElement(Select, {
                value: _optionalChain([app, 'access', _14 => _14.benchmarks, 'access', _15 => _15.benchmark, 'optionalAccess', _16 => _16.company_revenue]) || "",
                onChange: (e) => {
                  app.benchmarks.updateCurrentBenchmark(
                    "company_revenue",
                    e.target.value 
                  );
                  if (app.parse.user) {
                    app.parse.updateUserMeta(
                      "companyRevenue",
                      e.target.value ,
                      _optionalChain([app, 'access', _17 => _17.parse, 'access', _18 => _18.user, 'optionalAccess', _19 => _19.objectId])
                    );
                  }
                },
                label: "Umsatz", __self: this, __source: {fileName: _jsxFileName, lineNumber: 126}}

                , Object.entries(app.benchmarks.revenueOptions).map(
                  ([key, label]) => (
                    React.createElement(MenuItem, { key: key, value: key, __self: this, __source: {fileName: _jsxFileName, lineNumber: 145}}
                      , key === "" ? React.createElement('em', {__self: this, __source: {fileName: _jsxFileName, lineNumber: 146}}, label) : label
                    )
                  )
                )
              )
            )

            , Object.keys(app.benchmarks.answers).length > 0 && (
              React.createElement(Button, {
                variant: "contained",
                color: "primary",
                onClick: () => {
                  navigate("/check/edit");
                }, __self: this, __source: {fileName: _jsxFileName, lineNumber: 154}}
, "Zurück zur Übersicht"

              )
            )

            , Object.keys(app.benchmarks.answers).length === 0 && (
              React.createElement(Button, {
                variant: "contained",
                color: "primary",
                onClick: () => {
                  // navigate(
                  //   `/benchmark/edit/category/${app.benchmarks.categories[0]?.objectId}`
                  // );
                  navigate(`/check/edit/finish`);
                }, __self: this, __source: {fileName: _jsxFileName, lineNumber: 166}}
, "Weiter"

              )
            )
          )
        )
      )
    );
  }
);

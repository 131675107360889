const _jsxFileName = "/usr/src/app/app/js/components/AdminIndustryRoute.tsx";import React from "react";
import { observer } from "mobx-react-lite";
import { useApp } from "../context";

import { AdminList } from "./AdminList";

export const AdminIndustryRoute = observer(function AdminIndustryRoute() {
  const app = useApp();

  return (
    React.createElement(AdminList, {
      title: "Branchen",
      className: "Industry",
      navName: "industry",
      rows: app.benchmarks.industries, __self: this, __source: {fileName: _jsxFileName, lineNumber: 11}}
    )
  );
});

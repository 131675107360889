const _jsxFileName = "/usr/src/app/app/js/components/BenchmarkEditCategoryRoute.tsx"; function _optionalChain(ops) { let lastAccessLHS = undefined; let value = ops[0]; let i = 1; while (i < ops.length) { const op = ops[i]; const fn = ops[i + 1]; i += 2; if ((op === 'optionalAccess' || op === 'optionalCall') && value == null) { return undefined; } if (op === 'access' || op === 'optionalAccess') { lastAccessLHS = value; value = fn(value); } else if (op === 'call' || op === 'optionalCall') { value = fn((...args) => value.call(lastAccessLHS, ...args)); lastAccessLHS = undefined; } } return value; }import React, { useState } from "react";
import { observer } from "mobx-react-lite";
import { useApp } from "../context";

import { useNavigate, useParams } from "react-router-dom";
import { Button, Grid, makeStyles, Paper, Typography } from "@material-ui/core";

import { ErrorMessage } from "./ErrorMessage";
import { BenchmarkEditCategoryQuestions } from "./BenchmarkEditCategoryQuestions";

const useStyles = makeStyles((theme) => ({
  categoryHeading: {},
  questionContainer: {
    display: "block",
    width: "100%",
  },
  questionCheck: {
    color: theme.palette.success.main,
    marginLeft: 10,
  },
  formControl: {
    display: "block",
  },
  radioGroup: {
    padding: 0,
    marginBottom: theme.spacing(2),
    borderRadius: theme.shape.borderRadius,
    fontSize: "0.9em",
    display: "flex",
    width: "100%",
  },
  radioGroupLabel: {
    display: "block",
    width: "100%",
    margin: 0,
    padding: theme.spacing(1),

    borderBottomWidth: 1,
    borderBottomStyle: "solid",
    borderBottomColor: "#eee",

    "&:nth-last-child(1)": {
      borderBottomWidth: 0,
    },

    [theme.breakpoints.up("md")]: {
      width: "25%",

      borderRightWidth: 1,
      borderRightStyle: "solid",
      borderRightColor: "#eee",

      "&:nth-last-child(1)": {
        borderRightWidth: 0,
      },
    },
  },
  radioGroupRadio: {
    float: "right",
    paddingTop: 0,
    paddingRight: 0,
  },
  videoWrapper: {
    marginBottom: theme.spacing(2),
    position: "relative",
    paddingTop: "56.25%",
  },
  videoContainer: {
    position: "absolute",
    top: 0,
    left: 0,
    width: "100%",
    height: "100%",
  },
  marginBottom: {
    marginBottom: theme.spacing(2),
  },
  padding: {
    padding: theme.spacing(2),
    display: "flex",
  },
  navButton: {
    marginRight: theme.spacing(2),
  },
}));

export const BenchmarkEditCategoryRoute = observer(
  function BenchmarkEditCategoryRoute() {
    const app = useApp();
    const navigate = useNavigate();
    const classes = useStyles();

    const { categoryId } = useParams();

    const [currentQuestion, setCurrentQuestion] = React.useState(
      null
    );

    const [isLastSlide, setIsLastSlide] = useState(false);

    const [lastQuestionCategory, setLastQuestionCategory] = useState(false);

    const handleQuestionAnswered = (data, index) => {
      setLastQuestionCategory(data);
      if (index === questions.length + 1) {
        setIsLastSlide(true);
      } else {
        setIsLastSlide(false);
      }
    };

    const categories = app.benchmarks.categories;

    const category = categories.find((c) => c.objectId === categoryId);

    const nextCategoryId = category
      ? _optionalChain([categories, 'access', _ => _[categories.indexOf(category) + 1], 'optionalAccess', _2 => _2.objectId])
      : null;

    const previousCategoryId = category
      ? _optionalChain([categories, 'access', _3 => _3[categories.indexOf(category) - 1], 'optionalAccess', _4 => _4.objectId])
      : null;

    const lastQuestion =
      Object.keys(
        app.benchmarks.benchmarks[app.benchmarks.currentBenchmark].answers
      ).length === app.benchmarks.questions.length;

    const questions = app.benchmarks.questions.filter(
      (q) => _optionalChain([q, 'access', _5 => _5.category, 'optionalAccess', _6 => _6.objectId]) === _optionalChain([category, 'optionalAccess', _7 => _7.objectId])
    );

    React.useEffect(() => {
      if (category && questions.length > 0) {
        const firstUnanswerd = questions.find(
          (q) => !app.benchmarks.answers[q.objectId]
        );

        if (firstUnanswerd) {
          setCurrentQuestion(firstUnanswerd.objectId);
        } else {
          setCurrentQuestion(questions[0].objectId);
        }
      } else {
        setCurrentQuestion(null);
      }
    }, [categoryId]);

    if (!category) {
      return (
        React.createElement(ErrorMessage, {
          icon: "fa:exclamation-circle",
          title: "Handlungsfeld nicht gefunden"  ,
          message: "...", __self: this, __source: {fileName: _jsxFileName, lineNumber: 151}}
        )
      );
    }

    return (
      React.createElement(Grid, { container: true, justifyContent: "center", spacing: 2, __self: this, __source: {fileName: _jsxFileName, lineNumber: 160}}
        , React.createElement(Grid, { item: true, xs: 12, __self: this, __source: {fileName: _jsxFileName, lineNumber: 161}}
          , React.createElement(Typography, {
            variant: "subtitle1",
            component: "h1",
            style: { marginBottom: "1.5rem" }, __self: this, __source: {fileName: _jsxFileName, lineNumber: 162}}
, "Dimension:"
            , " "
            , 
              _optionalChain([app, 'access', _8 => _8.benchmarks, 'access', _9 => _9.dimensions, 'access', _10 => _10.find, 'call', _11 => _11(
                (d) => d.objectId === _optionalChain([category, 'access', _12 => _12.dimension, 'optionalAccess', _13 => _13.objectId])
              ), 'optionalAccess', _14 => _14.name])
            
          )
          , React.createElement(Typography, { variant: "h4", component: "h1", __self: this, __source: {fileName: _jsxFileName, lineNumber: 174}}
            , category.name
          )
        )
        , React.createElement(Grid, { item: true, xs: 12, __self: this, __source: {fileName: _jsxFileName, lineNumber: 178}}
          , React.createElement(BenchmarkEditCategoryQuestions, {
            key: category.objectId,
            category: category,
            questions: questions,
            onQuestionAnswered: handleQuestionAnswered, __self: this, __source: {fileName: _jsxFileName, lineNumber: 179}}
          )
        )
        , React.createElement(Grid, { item: true, xs: 12, __self: this, __source: {fileName: _jsxFileName, lineNumber: 186}}
          , React.createElement(Paper, { className: classes.padding, __self: this, __source: {fileName: _jsxFileName, lineNumber: 187}}
            /* {previousCategoryId && (
              <Button
                className={classes.navButton}
                variant={
                  !nextCategoryId && !lastQuestion ? "contained" : "text"
                }
                color="primary"
                onClick={() => {
                  // navigate(`/check/edit/category/${previousCategoryId}`);
                  // console.log(lastQuestionCategory);
                }}
              >
                Vorheriges Handlungsfeld
              </Button>
            )} */

            , !nextCategoryId && (
              React.createElement(Button, {
                className: classes.navButton,
                variant: "text",
                color: "primary",
                onClick: () => {
                  navigate(`/check/edit`);
                }, __self: this, __source: {fileName: _jsxFileName, lineNumber: 205}}
, "Zur Übersicht"

              )
            )

            , nextCategoryId && (
              React.createElement(Button, {
                className: classes.navButton,
                variant: "text",
                color: "primary",
                onClick: () => {
                  navigate(`/check/edit`);
                }, __self: this, __source: {fileName: _jsxFileName, lineNumber: 218}}
, "Zur Übersicht"

              )
            )

            , nextCategoryId && !lastQuestion && lastQuestionCategory && (
              React.createElement(Button, {
                className: classes.navButton,
                variant: "contained",
                color: "primary",
                onClick: () => {
                  navigate(`/check/edit/category/${nextCategoryId}`);
                },
                style: { marginLeft: "auto" }, __self: this, __source: {fileName: _jsxFileName, lineNumber: 231}}
, "Nächstes Handlungsfeld"

              )
            )

            , nextCategoryId &&
              !lastQuestion &&
              !lastQuestionCategory &&
              isLastSlide && (
                React.createElement(Button, {
                  className: classes.navButton,
                  variant: "contained",
                  color: "primary",
                  onClick: () => {
                    navigate(`/check/edit/category/${nextCategoryId}`);
                  },
                  style: { marginLeft: "auto" }, __self: this, __source: {fileName: _jsxFileName, lineNumber: 248}}
, "Nächstes Handlungsfeld"

                )
              )

            , lastQuestion && (
              React.createElement(Button, {
                className: classes.navButton,
                variant: "contained",
                color: "primary",
                onClick: () => {
                  app.benchmarks.finishCurrentBenchmark();
                  navigate("/check/edit/evaluation");
                },
                style: { marginLeft: "auto" }, __self: this, __source: {fileName: _jsxFileName, lineNumber: 262}}
, "Zur Auswertung"

              )
            )
          )
        )
      )
    );
  }
);

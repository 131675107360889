const _jsxFileName = "/usr/src/app/app/js/components/Navigation.tsx"; function _optionalChain(ops) { let lastAccessLHS = undefined; let value = ops[0]; let i = 1; while (i < ops.length) { const op = ops[i]; const fn = ops[i + 1]; i += 2; if ((op === 'optionalAccess' || op === 'optionalCall') && value == null) { return undefined; } if (op === 'access' || op === 'optionalAccess') { lastAccessLHS = value; value = fn(value); } else if (op === 'call' || op === 'optionalCall') { value = fn((...args) => value.call(lastAccessLHS, ...args)); lastAccessLHS = undefined; } } return value; }import React from "react";
import { observer } from "mobx-react-lite";
import { useApp } from "../context";
import { Link } from "react-router-dom";

export const Navigation = observer(function Navigation() {
  const app = useApp();

  if (_optionalChain([app, 'access', _ => _.parse, 'access', _2 => _2.user, 'optionalAccess', _3 => _3.isAdmin])) {
    return (
      React.createElement(React.Fragment, null
        , React.createElement(Link, { to: "admin", __self: this, __source: {fileName: _jsxFileName, lineNumber: 12}}, "Admin")
        , React.createElement(Link, { to: "logout", __self: this, __source: {fileName: _jsxFileName, lineNumber: 13}}, "Abmelden")
      )
    );
  }

  if (app.parse.user) {
    return (
      React.createElement(React.Fragment, null
        , React.createElement(Link, { to: "check/summary", __self: this, __source: {fileName: _jsxFileName, lineNumber: 21}}, "Historie")
        , React.createElement(Link, { to: "logout", __self: this, __source: {fileName: _jsxFileName, lineNumber: 22}}, "Abmelden")
      )
    );
  }

  if (!app.parse.user) {
    return (
      React.createElement(React.Fragment, null
        , React.createElement(Link, { to: "userLogin", __self: this, __source: {fileName: _jsxFileName, lineNumber: 30}}, "Anmelden")
      )
    );
  }

  return (
    React.createElement(React.Fragment, null
      /* <a
        href="https://www.digitalradar-muensterland.de/"
        title="Zur Startseite von Digitalradar Münsterland"
        target="_blank"
        rel="noopener noreferrer"
      >
        Zur Startseite
      </a> */
      /* <Link to="benchmark">Benchmark</Link>
      <Link to="login">Login</Link> */
    )
  );
});

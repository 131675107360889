const _jsxFileName = "/usr/src/app/app/js/components/ReportRoute.tsx"; function _optionalChain(ops) { let lastAccessLHS = undefined; let value = ops[0]; let i = 1; while (i < ops.length) { const op = ops[i]; const fn = ops[i + 1]; i += 2; if ((op === 'optionalAccess' || op === 'optionalCall') && value == null) { return undefined; } if (op === 'access' || op === 'optionalAccess') { lastAccessLHS = value; value = fn(value); } else if (op === 'call' || op === 'optionalCall') { value = fn((...args) => value.call(lastAccessLHS, ...args)); lastAccessLHS = undefined; } } return value; }import React from "react";
import { observer } from "mobx-react-lite";
import { useApp } from "../context";
import { useParams } from "react-router-dom";
import {
  makeStyles,
  CircularProgress,
  Paper,
  useTheme,
  MenuList,
  MenuItem,
  Grid,
  Typography,
  TextField,
} from "@material-ui/core";

import { Highcharts } from "../helper/Highcharts";

import HighchartsReact from "highcharts-react-official";

import { ErrorMessage } from "./ErrorMessage";

const useStyles = makeStyles((theme) => ({
  loading: {
    textAlign: "center",
    padding: "50px 0",
  },
  textbox: {
    padding: theme.spacing(2),
  },
  padding: {
    padding: theme.spacing(2),
  },
  marginBottom: {
    marginBottom: theme.spacing(2),
  },
}));

export const ReportRoute = observer(function BenchmarkReportRoute() {
  const app = useApp();
  const theme = useTheme();
  const classes = useStyles();
  const { id, secret } = useParams();

  const [category, setCategory] = React.useState("all");

  React.useEffect(() => {
    app.report.fetchReport(id, secret);
  }, [id, secret]);

  if (app.report.error) {
    return (
      React.createElement(ErrorMessage, {
        icon: "fa:exclamation-circle",
        title: "Unbekannter Fehler" ,
        message: "Der Report konnte nicht geladen werden."     , __self: this, __source: {fileName: _jsxFileName, lineNumber: 53}}
      )
    );
  }

  if (app.report.loading) {
    return (
      React.createElement('div', { className: classes.loading, __self: this, __source: {fileName: _jsxFileName, lineNumber: 63}}
        , React.createElement(CircularProgress, {__self: this, __source: {fileName: _jsxFileName, lineNumber: 64}} )
      )
    );
  }

  if (!app.report.report) {
    return (
      React.createElement(ErrorMessage, {
        icon: "fa:exclamation-circle",
        title: "Fehler",
        message: "Es wurde kein Report geladen."    , __self: this, __source: {fileName: _jsxFileName, lineNumber: 71}}
      )
    );
  }

  return (
    React.createElement(Grid, { container: true, spacing: 2, __self: this, __source: {fileName: _jsxFileName, lineNumber: 80}}
      , React.createElement(Grid, { item: true, md: 4, xs: 12, __self: this, __source: {fileName: _jsxFileName, lineNumber: 81}}
        , React.createElement(Paper, {__self: this, __source: {fileName: _jsxFileName, lineNumber: 82}}
          , React.createElement(MenuList, {__self: this, __source: {fileName: _jsxFileName, lineNumber: 83}}
            , Object.entries(app.report.categoryMap).map(([key, name]) => (
              React.createElement(MenuItem, {
                key: key,
                button: true,
                selected: category === key,
                onClick: () => {
                  setCategory(key);
                }, __self: this, __source: {fileName: _jsxFileName, lineNumber: 85}}

                , name
              )
            ))
          )
        )
      )
      , React.createElement(Grid, { item: true, md: 8, xs: 12, __self: this, __source: {fileName: _jsxFileName, lineNumber: 99}}
        , React.createElement(Paper, {__self: this, __source: {fileName: _jsxFileName, lineNumber: 100}}
          , React.createElement('div', { className: classes.textbox, __self: this, __source: {fileName: _jsxFileName, lineNumber: 101}}
            , React.createElement(Typography, { variant: "h5", component: "h2", __self: this, __source: {fileName: _jsxFileName, lineNumber: 102}}
              , app.report.categoryMap[category]
            )
            , React.createElement(Typography, { variant: "body1", __self: this, __source: {fileName: _jsxFileName, lineNumber: 105}}, "Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum. Stet clita kasd gubergren."




            )
          )

          , React.createElement(HighchartsReact, {
            highcharts: Highcharts,
            options: app.report.getChart(
              category,
              theme.palette.primary.main,
              theme.palette.secondary.main
            ), __self: this, __source: {fileName: _jsxFileName, lineNumber: 113}}
          )

          , React.createElement('div', { className: classes.textbox, __self: this, __source: {fileName: _jsxFileName, lineNumber: 122}}
            , app.benchmarks.questions
              .filter((q) => _optionalChain([q, 'access', _ => _.category, 'optionalAccess', _2 => _2.objectId]) === category)
              .map((q) => (
                React.createElement(Typography, { key: q.objectId, variant: "body1", __self: this, __source: {fileName: _jsxFileName, lineNumber: 126}}
                  , React.createElement('b', {__self: this, __source: {fileName: _jsxFileName, lineNumber: 127}}, "Auswertung zu "  , q.name, ": " )
                  , _optionalChain([app, 'access', _3 => _3.report, 'access', _4 => _4.report, 'optionalAccess', _5 => _5.questionsCurrentText, 'access', _6 => _6[q.objectId]])
                )
              ))
          )

          /* <pre>{JSON.stringify(app.report.report, null, 2)}</pre> */
        )
      )
      , React.createElement(Grid, { item: true, xs: 12, className: classes.marginBottom, __self: this, __source: {fileName: _jsxFileName, lineNumber: 136}}
        , React.createElement(Paper, { className: classes.padding, __self: this, __source: {fileName: _jsxFileName, lineNumber: 137}}
          , React.createElement(Typography, { variant: "body1", className: classes.marginBottom, __self: this, __source: {fileName: _jsxFileName, lineNumber: 138}}, "Du kannst den folgenden Link einem Kollegen geben, mit dem du den Benchmark Report teilen möchtest."


          )
          , React.createElement(TextField, {
            value: app.report.reportUrl,
            label: "Report teilen" ,
            variant: "outlined",
            fullWidth: true,
            onFocus: (e) => {
              e.target.select();
            }, __self: this, __source: {fileName: _jsxFileName, lineNumber: 142}}
          )
        )
      )
    )
  );
});

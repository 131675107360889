const _jsxFileName = "/usr/src/app/app/js/components/AdminQuestionEditRoute.tsx"; function _optionalChain(ops) { let lastAccessLHS = undefined; let value = ops[0]; let i = 1; while (i < ops.length) { const op = ops[i]; const fn = ops[i + 1]; i += 2; if ((op === 'optionalAccess' || op === 'optionalCall') && value == null) { return undefined; } if (op === 'access' || op === 'optionalAccess') { lastAccessLHS = value; value = fn(value); } else if (op === 'call' || op === 'optionalCall') { value = fn((...args) => value.call(lastAccessLHS, ...args)); lastAccessLHS = undefined; } } return value; }import React from "react";
import { observer } from "mobx-react-lite";
import { useParams } from "react-router-dom";
import {
  FormControl,
  InputLabel,
  makeStyles,
  MenuItem,
  Select,
  TextField,
} from "@material-ui/core";

import { useApp } from "../context";
import { ErrorMessage } from "./ErrorMessage";

const useStyles = makeStyles((theme) => ({
  padding: {
    padding: theme.spacing(2),
  },
  formControl: {
    width: "100%",
    marginBottom: 20,
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
}));

const FIELDS = [
  {
    label: "Name",
    key: "name",
    multi: false,
  },
  {
    label: "Name (kurz)",
    key: "name_short",
    multi: false,
  },
  {
    label: "Reihenfolge",
    key: "order",
    multi: false,
    number: true,
  },
  // {
  //   label: "Beschreibung",
  //   key: "description",
  //   multi: true,
  // },
  // {
  //   label: "YouTube Link",
  //   key: "youtube_url",
  //   multi: false,
  // },
  {
    label: "Antwort (1)",
    key: "answer_1_text",
    multi: true,
  },
  {
    label: "Antwort (2)",
    key: "answer_2_text",
    multi: true,
  },
  {
    label: "Antwort (3)",
    key: "answer_3_text",
    multi: true,
  },
  {
    label: "Antwort (4)",
    key: "answer_4_text",
    multi: true,
  },
  {
    label: "Antwort (5)",
    key: "answer_5_text",
    multi: true,
  },
  // {
  //   label: "Ergebnis (1)",
  //   key: "answer_1_response",
  //   multi: true,
  // },
  // {
  //   label: "Ergebnis (2)",
  //   key: "answer_2_response",
  //   multi: true,
  // },
  // {
  //   label: "Ergebnis (3)",
  //   key: "answer_3_response",
  //   multi: true,
  // },
  // {
  //   label: "Ergebnis (4)",
  //   key: "answer_4_response",
  //   multi: true,
  // },
];

export const AdminQuestionEditRoute = observer(
  function AdminQuestionEditRoute() {
    const app = useApp();
    const classes = useStyles();

    const { id } = useParams();

    const question = app.benchmarks.questions.find((o) => o.objectId === id);

    if (!question) {
      return (
        React.createElement(ErrorMessage, {
          icon: "fa:exclamation-circle",
          title: "Frage nicht gefunden"  ,
          message: "Bitte den Bearbeiten Dialog neu öffnen."     , __self: this, __source: {fileName: _jsxFileName, lineNumber: 114}}
        )
      );
    }

    return (
      React.createElement(React.Fragment, null
        , React.createElement(FormControl, { variant: "outlined", className: classes.formControl, __self: this, __source: {fileName: _jsxFileName, lineNumber: 124}}
          , React.createElement(InputLabel, {__self: this, __source: {fileName: _jsxFileName, lineNumber: 125}}, "Kategorie")
          , React.createElement(Select, {
            value: _optionalChain([question, 'access', _ => _.category, 'optionalAccess', _2 => _2.objectId]) || "",
            onChange: (e) => {
              app.admin.updateQuestion(id, {
                category: {
                  __type: "Pointer",
                  className: "Category",
                  objectId: e.target.value ,
                },
              });
            },
            label: "Kategorie", __self: this, __source: {fileName: _jsxFileName, lineNumber: 126}}

            , app.benchmarks.categories.map((c) => (
              React.createElement(MenuItem, { key: c.objectId, value: c.objectId, __self: this, __source: {fileName: _jsxFileName, lineNumber: 140}}
                , c.name
              )
            ))
          )
        )

        , FIELDS.map((field) => (
          React.createElement(TextField, {
            key: field.key,
            type: field.number ? "number" : undefined,
            variant: "outlined",
            margin: "normal",
            fullWidth: true,
            multiline: field.multi,
            label: field.label,
            // @ts-ignore
            defaultValue: question[field.key] || "",
            onBlur: (e) => {
              if (field.number) {
                app.admin.updateQuestion(id, {
                  [field.key]: parseInt(e.target.value),
                });
              } else {
                app.admin.updateQuestion(id, { [field.key]: e.target.value });
              }
            }, __self: this, __source: {fileName: _jsxFileName, lineNumber: 148}}
          )
        ))
      )
    );
  }
);

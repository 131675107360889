const _jsxFileName = "/usr/src/app/app/js/components/AdminCategoryRoute.tsx";import React from "react";
import { observer } from "mobx-react-lite";
import { useApp } from "../context";

import { AdminList } from "./AdminList";

export const AdminCategoryRoute = observer(function AdminCategoryRoute() {
  const app = useApp();

  return (
    React.createElement(AdminList, {
      title: "Kategorien",
      className: "Category",
      navName: "category",
      rows: app.benchmarks.categories, __self: this, __source: {fileName: _jsxFileName, lineNumber: 11}}
    )
  );
});

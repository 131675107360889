 function _optionalChain(ops) { let lastAccessLHS = undefined; let value = ops[0]; let i = 1; while (i < ops.length) { const op = ops[i]; const fn = ops[i + 1]; i += 2; if ((op === 'optionalAccess' || op === 'optionalCall') && value == null) { return undefined; } if (op === 'access' || op === 'optionalAccess') { lastAccessLHS = value; value = fn(value); } else if (op === 'call' || op === 'optionalCall') { value = fn((...args) => value.call(lastAccessLHS, ...args)); lastAccessLHS = undefined; } } return value; }import { autorun, makeObservable, observable } from "mobx";

import { UiService } from "./UiService";
import { NotificationService } from "./NotificationService";
import { ParseService } from "./ParseService";
import { BenchmarkService } from "./BenchmarkService";
import { ReportService } from "./ReportService";
import { AdminService } from "./AdminService";

export class AppService {
   __init() {this.cache = null}

   __init2() {this.loading = true}

   __init3() {this.ui = new UiService(this)}
   __init4() {this.notifications = new NotificationService(this)}
   __init5() {this.parse = new ParseService(this)}
   __init6() {this.benchmarks = new BenchmarkService(this)}
   __init7() {this.report = new ReportService(this)}
   __init8() {this.admin = new AdminService(this)}

  constructor() {;AppService.prototype.__init.call(this);AppService.prototype.__init2.call(this);AppService.prototype.__init3.call(this);AppService.prototype.__init4.call(this);AppService.prototype.__init5.call(this);AppService.prototype.__init6.call(this);AppService.prototype.__init7.call(this);AppService.prototype.__init8.call(this);
    // @ts-ignore
    window.app = this;

    makeObservable(this, {
      loading: observable,
    });

    this.init();
  }

   async init() {
    try {
      this.cache = JSON.parse(
        window.localStorage.getItem("benchmark.app.cache") || "null"
      );

      await this.ui.init(_optionalChain([this, 'access', _ => _.cache, 'optionalAccess', _2 => _2.ui]));
      await this.notifications.init(_optionalChain([this, 'access', _3 => _3.cache, 'optionalAccess', _4 => _4.notifications]));
      await this.parse.init(_optionalChain([this, 'access', _5 => _5.cache, 'optionalAccess', _6 => _6.parse]));
      await this.benchmarks.init(_optionalChain([this, 'access', _7 => _7.cache, 'optionalAccess', _8 => _8.benchmarks]));
      await this.report.init(_optionalChain([this, 'access', _9 => _9.cache, 'optionalAccess', _10 => _10.report]));
      await this.admin.init(_optionalChain([this, 'access', _11 => _11.cache, 'optionalAccess', _12 => _12.admin]));

      if (!this.parse.user) {
        this.benchmarks.currentBenchmark = null;
        this.benchmarks.benchmarks = {};
      }

      this.loading = false;

      autorun(() => {
        this.createCache();
      });
    } catch (error) {
      console.error(error);
    }
  }

   createCache() {
    const result = {};
    const blacklist = ["loading", "cache"];

    for (const key of Object.keys(this)) {
      if (!blacklist.includes(key)) {
        // @ts-ignore
        result[key] = this[key];
      }
    }

    const json = JSON.stringify(
      result,
      (key, value) => {
        if (key === "app" && value instanceof AppService) {
          return undefined;
        } else if (key === "cache" && value === this.cache) {
        } else {
          return value;
        }
      },
      2
    );

    window.localStorage.setItem("benchmark.app.cache", json);
  }
}

const _jsxFileName = "/usr/src/app/app/js/components/Router.tsx";import React from "react";
import { observer } from "mobx-react-lite";

import { Routes, Route, Navigate } from "react-router-dom";

import { BenchmarkHomeRoute } from "./BenchmarkHomeRoute";
import { BenchmarkResumeRoute } from "./BenchmarkResumeRoute";
import { BenchmarkResultRoute } from "./BenchmarkResultRoute";

import { BenchmarkEditRoute } from "./BenchmarkEditRoute";
import { BenchmarkEditOverviewRoute } from "./BenchmarkEditOverviewRoute";
import { BenchmarkEditMetaRoute } from "./BenchmarkEditMetaRoute";
import { BenchmarkEditFinishRoute } from "./BenchmarkEditFinishRoute";
import { BenchmarkEditCategoryRoute } from "./BenchmarkEditCategoryRoute";
import { BenchmarkEditEvaluationRoute } from "./BenchmarkEditEvaluationRoute";

import { BenchmarkSummaryRoute } from "./BenchmarkSummaryRoute";

import { ReportRoute } from "./ReportRoute";
import { InfoRoute } from "./InfoRoute";
import { MeasureRoute } from "./MeasureRoute";

import { AdminHomeRoute } from "./AdminHomeRoute";
import { AdminBenchmarkRoute } from "./AdminBenchmarkRoute";
import { AdminContactsRoute } from "./AdminContactsRoute";
import { AdminUserRoute } from "./AdminUserRoute";
import { AdminQuestionRoute } from "./AdminQuestionRoute";
import { AdminQuestionEditRoute } from "./AdminQuestionEditRoute";
import { AdminCategoryRoute } from "./AdminCategoryRoute";
import { AdminCategoryEditRoute } from "./AdminCategoryEditRoute";
import { AdminIndustryRoute } from "./AdminIndustryRoute";
import { AdminIndustryEditRoute } from "./AdminIndustryEditRoute";
import { AdminRegionRoute } from "./AdminRegionRoute";
import { AdminRegionEditRoute } from "./AdminRegionEditRoute";

import { LoginRoute } from "./LoginRoute";
import { LogoutRoute } from "./LogoutRoute";
import { UserLoginRoute } from "./UserLoginRoute";
import { UserRegisterRoute } from "./UserRegisterRoute";

import { NotFoundRoute } from "./NotFoundRoute";
import { AdminUserEditRoute } from "./AdminUserEditRoute";
import { BenchmarkCompletedRoute } from "./BenchmarkCompletedRoute";

import { Impressum } from "./Impressum";
import { Privacy } from "./Privacy";
import { ContactRoute } from "./ContactRoute";

export const Router = observer(function Router() {
  return (
    React.createElement(Routes, {__self: this, __source: {fileName: _jsxFileName, lineNumber: 51}}
      , React.createElement(Route, { path: "", element: React.createElement(BenchmarkHomeRoute, {__self: this, __source: {fileName: _jsxFileName, lineNumber: 52}} ), __self: this, __source: {fileName: _jsxFileName, lineNumber: 52}} )
      , React.createElement(Route, { path: "login", element: React.createElement(LoginRoute, {__self: this, __source: {fileName: _jsxFileName, lineNumber: 53}} ), __self: this, __source: {fileName: _jsxFileName, lineNumber: 53}} )
      , React.createElement(Route, { path: "logout", element: React.createElement(LogoutRoute, {__self: this, __source: {fileName: _jsxFileName, lineNumber: 54}} ), __self: this, __source: {fileName: _jsxFileName, lineNumber: 54}} )

      , React.createElement(Route, { path: "info", element: React.createElement(InfoRoute, {__self: this, __source: {fileName: _jsxFileName, lineNumber: 56}} ), __self: this, __source: {fileName: _jsxFileName, lineNumber: 56}} )
      , React.createElement(Route, { path: "info/:paragraphId", element: React.createElement(InfoRoute, {__self: this, __source: {fileName: _jsxFileName, lineNumber: 57}} ), __self: this, __source: {fileName: _jsxFileName, lineNumber: 57}} )

      , React.createElement(Route, { path: "measure", element: React.createElement(MeasureRoute, {__self: this, __source: {fileName: _jsxFileName, lineNumber: 59}} ), __self: this, __source: {fileName: _jsxFileName, lineNumber: 59}} )
      , React.createElement(Route, { path: "measure/:measureId", element: React.createElement(MeasureRoute, {__self: this, __source: {fileName: _jsxFileName, lineNumber: 60}} ), __self: this, __source: {fileName: _jsxFileName, lineNumber: 60}} )

      , React.createElement(Route, { path: "check", element: React.createElement(BenchmarkHomeRoute, {__self: this, __source: {fileName: _jsxFileName, lineNumber: 62}} ), __self: this, __source: {fileName: _jsxFileName, lineNumber: 62}} )
      /* <Route path="benchmark/start" element={<BenchmarkStartRoute />} /> */
      , React.createElement(Route, { path: "check/finished", element: React.createElement(BenchmarkCompletedRoute, {__self: this, __source: {fileName: _jsxFileName, lineNumber: 64}} ), __self: this, __source: {fileName: _jsxFileName, lineNumber: 64}} )
      , React.createElement(Route, { path: "check/summary", element: React.createElement(BenchmarkSummaryRoute, {__self: this, __source: {fileName: _jsxFileName, lineNumber: 65}} ), __self: this, __source: {fileName: _jsxFileName, lineNumber: 65}} )
      , React.createElement(Route, { path: "check/edit", element: React.createElement(BenchmarkEditRoute, {__self: this, __source: {fileName: _jsxFileName, lineNumber: 66}} ), __self: this, __source: {fileName: _jsxFileName, lineNumber: 66}}
        , React.createElement(Route, { path: "", element: React.createElement(BenchmarkEditOverviewRoute, {__self: this, __source: {fileName: _jsxFileName, lineNumber: 67}} ), __self: this, __source: {fileName: _jsxFileName, lineNumber: 67}} )
        , React.createElement(Route, { path: "meta", element: React.createElement(BenchmarkEditMetaRoute, {__self: this, __source: {fileName: _jsxFileName, lineNumber: 68}} ), __self: this, __source: {fileName: _jsxFileName, lineNumber: 68}} )
        , React.createElement(Route, { path: "evaluation", element: React.createElement(BenchmarkEditEvaluationRoute, {__self: this, __source: {fileName: _jsxFileName, lineNumber: 69}} ), __self: this, __source: {fileName: _jsxFileName, lineNumber: 69}} )
        , React.createElement(Route, { path: "finish", element: React.createElement(BenchmarkEditFinishRoute, {__self: this, __source: {fileName: _jsxFileName, lineNumber: 70}} ), __self: this, __source: {fileName: _jsxFileName, lineNumber: 70}} )
        , React.createElement(Route, {
          path: "category/:categoryId",
          element: React.createElement(BenchmarkEditCategoryRoute, {__self: this, __source: {fileName: _jsxFileName, lineNumber: 73}} ), __self: this, __source: {fileName: _jsxFileName, lineNumber: 71}}
        )
      )

      , React.createElement(Route, {
        path: "check/resume/:id/:secret",
        element: React.createElement(BenchmarkResumeRoute, {__self: this, __source: {fileName: _jsxFileName, lineNumber: 79}} ), __self: this, __source: {fileName: _jsxFileName, lineNumber: 77}}
      )

      , React.createElement(Route, {
        path: "check/result/:id/:secret",
        element: React.createElement(BenchmarkResultRoute, {__self: this, __source: {fileName: _jsxFileName, lineNumber: 84}} ), __self: this, __source: {fileName: _jsxFileName, lineNumber: 82}}
      )

      , React.createElement(Route, { path: "report/:id/:secret", element: React.createElement(ReportRoute, {__self: this, __source: {fileName: _jsxFileName, lineNumber: 87}} ), __self: this, __source: {fileName: _jsxFileName, lineNumber: 87}} )

      , React.createElement(Route, { path: "admin", element: React.createElement(AdminHomeRoute, {__self: this, __source: {fileName: _jsxFileName, lineNumber: 89}} ), __self: this, __source: {fileName: _jsxFileName, lineNumber: 89}}
        , React.createElement(Route, { path: "", element: React.createElement(Navigate, { to: "/admin/users", __self: this, __source: {fileName: _jsxFileName, lineNumber: 90}} ), __self: this, __source: {fileName: _jsxFileName, lineNumber: 90}} )
        , React.createElement(Route, { path: "checks", element: React.createElement(AdminBenchmarkRoute, {__self: this, __source: {fileName: _jsxFileName, lineNumber: 91}} ), __self: this, __source: {fileName: _jsxFileName, lineNumber: 91}} )
        , React.createElement(Route, { path: "contacts", element: React.createElement(AdminContactsRoute, {__self: this, __source: {fileName: _jsxFileName, lineNumber: 92}} ), __self: this, __source: {fileName: _jsxFileName, lineNumber: 92}} )
        , React.createElement(Route, { path: "users", element: React.createElement(AdminUserRoute, {__self: this, __source: {fileName: _jsxFileName, lineNumber: 93}} ), __self: this, __source: {fileName: _jsxFileName, lineNumber: 93}}
          , React.createElement(Route, { path: "edit/:id", element: React.createElement(AdminUserEditRoute, {__self: this, __source: {fileName: _jsxFileName, lineNumber: 94}} ), __self: this, __source: {fileName: _jsxFileName, lineNumber: 94}} )
        )
        , React.createElement(Route, { path: "question", element: React.createElement(AdminQuestionRoute, {__self: this, __source: {fileName: _jsxFileName, lineNumber: 96}} ), __self: this, __source: {fileName: _jsxFileName, lineNumber: 96}}
          , React.createElement(Route, { path: "edit/:id", element: React.createElement(AdminQuestionEditRoute, {__self: this, __source: {fileName: _jsxFileName, lineNumber: 97}} ), __self: this, __source: {fileName: _jsxFileName, lineNumber: 97}} )
        )
        , React.createElement(Route, { path: "category", element: React.createElement(AdminCategoryRoute, {__self: this, __source: {fileName: _jsxFileName, lineNumber: 99}} ), __self: this, __source: {fileName: _jsxFileName, lineNumber: 99}}
          , React.createElement(Route, { path: "edit/:id", element: React.createElement(AdminCategoryEditRoute, {__self: this, __source: {fileName: _jsxFileName, lineNumber: 100}} ), __self: this, __source: {fileName: _jsxFileName, lineNumber: 100}} )
        )
        , React.createElement(Route, { path: "industry", element: React.createElement(AdminIndustryRoute, {__self: this, __source: {fileName: _jsxFileName, lineNumber: 102}} ), __self: this, __source: {fileName: _jsxFileName, lineNumber: 102}}
          , React.createElement(Route, { path: "edit/:id", element: React.createElement(AdminIndustryEditRoute, {__self: this, __source: {fileName: _jsxFileName, lineNumber: 103}} ), __self: this, __source: {fileName: _jsxFileName, lineNumber: 103}} )
        )
        , React.createElement(Route, { path: "region", element: React.createElement(AdminRegionRoute, {__self: this, __source: {fileName: _jsxFileName, lineNumber: 105}} ), __self: this, __source: {fileName: _jsxFileName, lineNumber: 105}}
          , React.createElement(Route, { path: "edit/:id", element: React.createElement(AdminRegionEditRoute, {__self: this, __source: {fileName: _jsxFileName, lineNumber: 106}} ), __self: this, __source: {fileName: _jsxFileName, lineNumber: 106}} )
        )
      )

      , React.createElement(Route, { path: "userLogin", element: React.createElement(UserLoginRoute, {__self: this, __source: {fileName: _jsxFileName, lineNumber: 110}} ), __self: this, __source: {fileName: _jsxFileName, lineNumber: 110}})
      , React.createElement(Route, { path: "userRegister", element: React.createElement(UserRegisterRoute, {__self: this, __source: {fileName: _jsxFileName, lineNumber: 111}} ), __self: this, __source: {fileName: _jsxFileName, lineNumber: 111}})

      , React.createElement(Route, { path: "impressum", element: React.createElement(Impressum, {__self: this, __source: {fileName: _jsxFileName, lineNumber: 113}} ), __self: this, __source: {fileName: _jsxFileName, lineNumber: 113}} )
      , React.createElement(Route, { path: "privacy", element: React.createElement(Privacy, {__self: this, __source: {fileName: _jsxFileName, lineNumber: 114}} ), __self: this, __source: {fileName: _jsxFileName, lineNumber: 114}} )
      , React.createElement(Route, { path: "contact", element: React.createElement(ContactRoute, {__self: this, __source: {fileName: _jsxFileName, lineNumber: 115}} ), __self: this, __source: {fileName: _jsxFileName, lineNumber: 115}} )

      , React.createElement(Route, { path: "*", element: React.createElement(NotFoundRoute, {__self: this, __source: {fileName: _jsxFileName, lineNumber: 117}} ), __self: this, __source: {fileName: _jsxFileName, lineNumber: 117}} )
    )
  );
});

const _jsxFileName = "/usr/src/app/app/js/components/Privacy.tsx";import React from "react";
import { observer } from "mobx-react-lite";
import styled from "styled-components";

const Container = styled.div`
  text-align: justify;
  a {
    color: rgb(0, 123, 196);
  }

  h1,
  h2 a {
    color: rgb(205, 10, 30);
  }
`;

export const Privacy = observer(function Privacy() {
  return (
    React.createElement(Container, {__self: this, __source: {fileName: _jsxFileName, lineNumber: 19}}
      , React.createElement('h1', {__self: this, __source: {fileName: _jsxFileName, lineNumber: 20}}, "Datenschutzinformation")
      , React.createElement('p', {__self: this, __source: {fileName: _jsxFileName, lineNumber: 21}}, "Im Rahmen der Nutzung dieser Webseite werden personenbezogene Daten von Ihnen durch uns als den für die Datenverarbeitung Verantwortlichen verarbeitet und für die Dauer gespeichert, die zur Erfüllung der festgelegten Zwecke und gesetzlicher Verpflichtungen erforderlich ist. Im Folgenden informieren wir Sie darüber, um welche Daten es sich dabei handelt, auf welche Weise sie verarbeitet werden und welche Rechte Ihnen diesbezüglich zustehen."







      )
      , React.createElement('p', {__self: this, __source: {fileName: _jsxFileName, lineNumber: 30}}, "Personenbezogene Daten sind gemäß Art. 4 Nr. 1 Datenschutzgrundverordnung (DSGVO) alle Informationen, die sich auf eine identifizierte oder identifizierbare natürliche Person beziehen."



      )
      , React.createElement('p', {__self: this, __source: {fileName: _jsxFileName, lineNumber: 35}}
        , React.createElement('a', { href: "#1", __self: this, __source: {fileName: _jsxFileName, lineNumber: 36}}, "1 Geltungsbereich" )
      )
      , React.createElement('p', {__self: this, __source: {fileName: _jsxFileName, lineNumber: 38}}
        , React.createElement('a', { href: "#2", __self: this, __source: {fileName: _jsxFileName, lineNumber: 39}}, "2 Name und Kontaktdaten des für die Verarbeitung Verantwortlichen sowie des betrieblichen Datenschutzbeauftragten"


        )
      )
      , React.createElement('p', {__self: this, __source: {fileName: _jsxFileName, lineNumber: 44}}
        , React.createElement('a', { href: "#3", __self: this, __source: {fileName: _jsxFileName, lineNumber: 45}}, "3 Verarbeitung personenbezogener Daten und Zwecke der Verarbeitung"

        )
      )
      , React.createElement('p', {__self: this, __source: {fileName: _jsxFileName, lineNumber: 49}}
        , React.createElement('a', { href: "#4", __self: this, __source: {fileName: _jsxFileName, lineNumber: 50}}, "4 Weitergabe von Daten"   )
      )
      , React.createElement('p', {__self: this, __source: {fileName: _jsxFileName, lineNumber: 52}}
        , React.createElement('a', { href: "#5", __self: this, __source: {fileName: _jsxFileName, lineNumber: 53}}, "5 Betroffenenrechte" )
      )
      , React.createElement('p', {__self: this, __source: {fileName: _jsxFileName, lineNumber: 55}}
        , React.createElement('a', { href: "#6", __self: this, __source: {fileName: _jsxFileName, lineNumber: 56}}, "6 Datensicherheit" )
      )
      , React.createElement('p', {__self: this, __source: {fileName: _jsxFileName, lineNumber: 58}}
        , React.createElement('a', { href: "#7", __self: this, __source: {fileName: _jsxFileName, lineNumber: 59}}, "7 Aktualität und Änderung dieser Datenschutzinformation"     )
      )
      , React.createElement('h2', { style: { paddingTop: "2em" }, __self: this, __source: {fileName: _jsxFileName, lineNumber: 61}}
        , React.createElement('a', { id: "1", __self: this, __source: {fileName: _jsxFileName, lineNumber: 62}}, "1 Geltungsbereich" )
      )
      , React.createElement('p', {__self: this, __source: {fileName: _jsxFileName, lineNumber: 64}}, "Diese Datenschutzinformation gilt für Datenverarbeitungen auf allen Webseiten des"

         , " "
        , React.createElement('b', {__self: this, __source: {fileName: _jsxFileName, lineNumber: 67}}, "Fraunhofer-Institut für Angewandte Informationstechnik FIT"    ), ", die auf diese Datenschutzinformation Bezug nehmen."

      )
      , React.createElement('h2', {__self: this, __source: {fileName: _jsxFileName, lineNumber: 70}}
        , React.createElement('a', { id: "2", __self: this, __source: {fileName: _jsxFileName, lineNumber: 71}}, "2 Name und Kontaktdaten des für die Verarbeitung Verantwortlichen sowie des betrieblichen Datenschutzbeauftragten"


        )
      )
      , React.createElement('p', {__self: this, __source: {fileName: _jsxFileName, lineNumber: 76}}, "Verantwortlicher im Sinne von Art. 4 Nr. 7 DSGVO:"        )
      , React.createElement('p', {__self: this, __source: {fileName: _jsxFileName, lineNumber: 77}}, "Fraunhofer-Gesellschaft zur Förderung der angewandten Forschung e.V."

        , React.createElement('br', {__self: this, __source: {fileName: _jsxFileName, lineNumber: 79}} ), " Hansastraße 27 c"   , React.createElement('br', {__self: this, __source: {fileName: _jsxFileName, lineNumber: 79}} ), " 80686 München"
      )
      , React.createElement('p', {__self: this, __source: {fileName: _jsxFileName, lineNumber: 81}}, "für ihr"

        , React.createElement('br', {__self: this, __source: {fileName: _jsxFileName, lineNumber: 83}} ), " Fraunhofer-Institut für Angewandte Informationstechnik FIT"
      )
      , React.createElement('p', {__self: this, __source: {fileName: _jsxFileName, lineNumber: 85}}, "Schloss Birlinghoven"

        , React.createElement('br', {__self: this, __source: {fileName: _jsxFileName, lineNumber: 87}} ), " 53757 Sankt Augustin, Germany"
      )
      , React.createElement('p', {__self: this, __source: {fileName: _jsxFileName, lineNumber: 89}}, "(im Folgenden "
          , React.createElement('b', {__self: this, __source: {fileName: _jsxFileName, lineNumber: 90}}, "Fraunhofer FIT" ), ")"
      )
      , React.createElement('p', {__self: this, __source: {fileName: _jsxFileName, lineNumber: 92}}, "E-Mail: "

        , React.createElement('a', { href: "mailto:info@fit.fraunhofer.de", target: "_blank", __self: this, __source: {fileName: _jsxFileName, lineNumber: 94}}, "info@fit.fraunhofer.de"

        )
        , React.createElement('br', {__self: this, __source: {fileName: _jsxFileName, lineNumber: 97}} ), " Telefon: +49 2241 14-3333"
        , React.createElement('br', {__self: this, __source: {fileName: _jsxFileName, lineNumber: 98}} ), " Fax: +49 2241 14-3700"
      )
      , React.createElement('p', {__self: this, __source: {fileName: _jsxFileName, lineNumber: 100}}, "Der Datenschutzbeauftragte von Fraunhofer ist unter der o.g. Anschrift in München, zu Hd. Datenschutzbeauftragter bzw. unter"

              , " "
        , React.createElement('a', { href: "mailto:datenschutz@zv.fraunhofer.de", target: "_blank", __self: this, __source: {fileName: _jsxFileName, lineNumber: 103}}, "datenschutz@zv.fraunhofer.de"

        ), " ", "erreichbar. Sie können sich jederzeit bei Fragen zum Datenschutzrecht oder Ihren Betroffenenrechten direkt an unseren Datenschutzbeauftragten wenden."



      )
      , React.createElement('p', {__self: this, __source: {fileName: _jsxFileName, lineNumber: 110}}, "Alternativ steht Ihnen unser Ansprechpartner Feras Nassaj unter"
               , " "
        , React.createElement('a', { href: "mailto:feras.nassaj@fit.fraunhofer.de", target: "_blank", __self: this, __source: {fileName: _jsxFileName, lineNumber: 112}}, "feras.nassaj@fit.fraunhofer.de"

        ), " ", "zur Verfügung."

      )
      , React.createElement('h2', {__self: this, __source: {fileName: _jsxFileName, lineNumber: 117}}
        , React.createElement('a', { id: "3", __self: this, __source: {fileName: _jsxFileName, lineNumber: 118}}, "3 Verarbeitung personenbezogener Daten und Zwecke der Verarbeitung"

        )
      )
      , React.createElement('p', {__self: this, __source: {fileName: _jsxFileName, lineNumber: 122}}
        , React.createElement('b', {__self: this, __source: {fileName: _jsxFileName, lineNumber: 123}}, "Beim Besuch der Webseite"   )
      )
      , React.createElement('p', {__self: this, __source: {fileName: _jsxFileName, lineNumber: 125}}, "Sie können unsere Webseite aufrufen, ohne Angaben zu Ihrer Identität preisgeben zu müssen. Der auf Ihrem Endgerät eingesetzte Browser sendet lediglich automatisch Informationen an den Server unserer Webseite (z.B. Browsertyp und -version, Datum und Uhrzeit des Zugriffs), um einen Verbindungsaufbau der Webseite zu ermöglichen. Hierzu gehört auch die IP-Adresse Ihres anfragenden Endgerätes. Diese wird temporär in einem sog. Logfile gespeichert und spätestens nach 30 Tagen gelöscht."







      )
      , React.createElement('p', {__self: this, __source: {fileName: _jsxFileName, lineNumber: 134}}, "Die Verarbeitung der IP-Adresse erfolgt zu technischen und administrativen Zwecken des Verbindungsaufbaus und -stabilität, um die Sicherheit und Funktionsfähigkeit unserer Webseite zu gewährleisten und etwaige rechtswidrige Angriffe hierauf notfalls verfolgen zu können."




      )
      , React.createElement('p', {__self: this, __source: {fileName: _jsxFileName, lineNumber: 140}}, "Die Rechtsgrundlage für die Verarbeitung der IP-Adresse ist Art. 6 Abs. 1 S. 1 lit. f DSGVO. Unser berechtigtes Interesse folgt aus dem erwähnten Sicherheitsinteresse und der Erforderlichkeit einer störungsfreien Bereitstellung unserer Webseite."




      )
      , React.createElement('p', {__self: this, __source: {fileName: _jsxFileName, lineNumber: 146}}, "Aus der Verarbeitung der IP-Adresse und anderer Information in dem Logfile können wir keine unmittelbaren Rückschlüsse auf Ihre Identität ziehen."



      )
      , React.createElement('h2', {__self: this, __source: {fileName: _jsxFileName, lineNumber: 151}}
        , React.createElement('a', { id: "4", __self: this, __source: {fileName: _jsxFileName, lineNumber: 152}}, "4 Weitergabe von Daten"   )
      )
      , React.createElement('p', {__self: this, __source: {fileName: _jsxFileName, lineNumber: 154}}, "Sofern wir personenbezogene Daten, die wir über die Webseite erheben, an Auftragsverarbeiter weitergeben, informieren wir Sie darüber in dieser Datenschutzinformation bei dem jeweiligen Datenverarbeitungsvorgang unter Nennung des konkreten Empfängers."




      )
      , React.createElement('p', {__self: this, __source: {fileName: _jsxFileName, lineNumber: 160}}, "Im Übrigen geben wir Ihre personenbezogenen Daten nur weiter, wenn"         )
      , React.createElement('ul', {__self: this, __source: {fileName: _jsxFileName, lineNumber: 161}}
        , React.createElement('li', {__self: this, __source: {fileName: _jsxFileName, lineNumber: 162}}, "Sie gem. Art. 6 Abs. 1 S. 1 lit. a DSGVO Ihre ausdrückliche Einwilligung dazu erteilt haben;"


        )
        , React.createElement('li', {__self: this, __source: {fileName: _jsxFileName, lineNumber: 166}}, "dies gem. Art. 6 Abs. 1 S. 1 lit. b DSGVO für die Erfüllung eines Vertrages mit Ihnen erforderlich ist (zum Beispiel bei Weitergabe an Versandunternehmen zum Zwecke der Lieferung der von Ihnen bestellten Ware oder bei Weitergabe von Zahlungsdaten an Zahlungsdienstleister oder Kreditinstitute, um einen Zahlungsvorgang durchzuführen);"





        )
        , React.createElement('li', {__self: this, __source: {fileName: _jsxFileName, lineNumber: 173}}, "für die Weitergabe nach Art. 6 Abs. 1 S. 1 lit. c DSGVO eine gesetzliche Verpflichtung besteht."


        )
      )
      , React.createElement('p', {__self: this, __source: {fileName: _jsxFileName, lineNumber: 178}}, "Die weitergegebenen Daten dürfen von den Empfängern ausschließlich zu den genannten Zwecken verwendet werden."


      )
      , React.createElement('h2', {__self: this, __source: {fileName: _jsxFileName, lineNumber: 182}}
        , React.createElement('a', { id: "5", __self: this, __source: {fileName: _jsxFileName, lineNumber: 183}}, "5 Betroffenenrechte" )
      )
      , React.createElement('p', {__self: this, __source: {fileName: _jsxFileName, lineNumber: 185}}, "Sie haben das Recht:"   )
      , React.createElement('ul', {__self: this, __source: {fileName: _jsxFileName, lineNumber: 186}}
        , React.createElement('li', {__self: this, __source: {fileName: _jsxFileName, lineNumber: 187}}
          , React.createElement('span', {__self: this, __source: {fileName: _jsxFileName, lineNumber: 188}}, "gemäß Art. 7 Abs. 3 DSGVO Ihre einmal erteilte Einwilligung jederzeit gegenüber uns zu widerrufen. Dies hat zur Folge, dass wir die Datenverarbeitung, die auf dieser Einwilligung beruhte, für die Zukunft nicht mehr fortführen dürfen;"




          )
        )
        , React.createElement('li', {__self: this, __source: {fileName: _jsxFileName, lineNumber: 195}}
          , React.createElement('span', {__self: this, __source: {fileName: _jsxFileName, lineNumber: 196}}, "gemäß Art. 15 DSGVO Auskunft über Ihre von uns verarbeiteten personenbezogenen Daten zu verlangen. Insbesondere können Sie Auskunft über die Verarbeitungszwecke, die Kategorie der personenbezogenen Daten, die Kategorien von Empfängern, gegenüber denen Ihre Daten offengelegt wurden oder werden, die geplante Speicherdauer, das Bestehen eines Rechts auf Berichtigung, Löschung, Einschränkung der Verarbeitung oder Widerspruch, das Bestehen eines Beschwerderechts, die Herkunft ihrer Daten, sofern diese nicht bei uns erhoben wurden, sowie über das Bestehen einer automatisierten Entscheidungsfindung einschließlich Profiling und ggf. aussagekräftigen Informationen zu deren Einzelheiten verlangen;"











          )
        )
        , React.createElement('li', {__self: this, __source: {fileName: _jsxFileName, lineNumber: 210}}
          , React.createElement('span', {__self: this, __source: {fileName: _jsxFileName, lineNumber: 211}}, "gemäß Art. 16 DSGVO unverzüglich die Berichtigung unrichtiger oder Vervollständigung Ihrer bei uns gespeicherten personenbezogenen Daten zu verlangen;"



          )
        )
        , React.createElement('li', {__self: this, __source: {fileName: _jsxFileName, lineNumber: 217}}
          , React.createElement('span', {__self: this, __source: {fileName: _jsxFileName, lineNumber: 218}}, "gemäß Art. 17 DSGVO die Löschung Ihrer bei uns gespeicherten personenbezogenen Daten zu verlangen, soweit nicht die Verarbeitung zur Ausübung des Rechts auf freie Meinungsäußerung und Information, zur Erfüllung einer rechtlichen Verpflichtung, aus Gründen des öffentlichen Interesses oder zur Geltendmachung, Ausübung oder Verteidigung von Rechtsansprüchen erforderlich ist;"






          )
        )
        , React.createElement('li', {__self: this, __source: {fileName: _jsxFileName, lineNumber: 227}}
          , React.createElement('span', {__self: this, __source: {fileName: _jsxFileName, lineNumber: 228}}, "gemäß Art. 18 DSGVO die Einschränkung der Verarbeitung Ihrer personenbezogenen Daten zu verlangen, soweit die Richtigkeit der Daten von Ihnen bestritten wird, die Verarbeitung unrechtmäßig ist, Sie aber deren Löschung ablehnen und wir die Daten nicht mehr benötigen, Sie jedoch diese zur Geltendmachung, Ausübung oder Verteidigung von Rechtsansprüchen benötigen oder Sie gemäß Art. 21 DSGVO Widerspruch gegen die Verarbeitung eingelegt haben;"







          )
        )
        , React.createElement('li', {__self: this, __source: {fileName: _jsxFileName, lineNumber: 238}}
          , React.createElement('span', {__self: this, __source: {fileName: _jsxFileName, lineNumber: 239}}, "gemäß Art. 20 DSGVO Ihre personenbezogenen Daten, die Sie uns bereitgestellt haben, in einem strukturierten, gängigen und maschinenlesbaren Format zu erhalten oder die Übermittlung an einen anderen Verantwortlichen zu verlangen und"




          )
        )
        , React.createElement('li', {__self: this, __source: {fileName: _jsxFileName, lineNumber: 246}}
          , React.createElement('span', {__self: this, __source: {fileName: _jsxFileName, lineNumber: 247}}, "gemäß Art. 77 DSGVO sich bei einer Aufsichtsbehörde zu beschweren. In der Regel können Sie sich hierfür an die Aufsichtsbehörde ihres üblichen Aufenthaltsortes oder Arbeitsplatzes oder unseres Unternehmenssitzes wenden."




          )
        )
      )
      , React.createElement('p', {__self: this, __source: {fileName: _jsxFileName, lineNumber: 255}}, " ")
      , React.createElement('p', {__self: this, __source: {fileName: _jsxFileName, lineNumber: 256}}
        , React.createElement('b', {__self: this, __source: {fileName: _jsxFileName, lineNumber: 257}}, "Information über Ihr Widerspruchsrecht nach Art. 21 DSGVO"       )
      )
      , React.createElement('p', {__self: this, __source: {fileName: _jsxFileName, lineNumber: 259}}
        , React.createElement('b', {__self: this, __source: {fileName: _jsxFileName, lineNumber: 260}}, "Sie haben das Recht, aus Gründen, die sich aus Ihrer besonderen Situation ergeben, jederzeit gegen die Verarbeitung Sie betreffender personenbezogener Daten, die aufgrund von Artikel 6 Absatz 1 Buchstabe e DSGVO (Datenverarbeitung im öffentlichen Interesse) und Artikel 6 Absatz 1 Buchstabe f DSGVO (Datenverarbeitung auf der Grundlage einer Interessenabwägung) erfolgt, Widerspruch einzulegen; dies gilt auch für ein auf diese Bestimmung gestütztes Profiling von Artikel 4 Nr. 4 DSGVO."








        )
      ), " "
      , React.createElement('p', {__self: this, __source: {fileName: _jsxFileName, lineNumber: 271}}
        , React.createElement('b', {__self: this, __source: {fileName: _jsxFileName, lineNumber: 272}}, "Legen Sie Widerspruch ein, werden wir Ihre personenbezogenen Daten nicht mehr verarbeiten, es sei denn, wir können zwingende schutzwürdige Gründe für die Verarbeitung nachweisen, die Ihre Interessen, Rechte und Freiheiten überwiegen, oder die Verarbeitung dient der Geltendmachung, Ausübung oder Verteidigung von Rechtsansprüchen."






        )
      ), " "
      , React.createElement('p', {__self: this, __source: {fileName: _jsxFileName, lineNumber: 281}}
        , React.createElement('b', {__self: this, __source: {fileName: _jsxFileName, lineNumber: 282}}, "Sofern sich Ihr Widerspruch gegen eine Verarbeitung von Daten zum Zwecke der Direktwerbung richtet, so werden wir die Verarbeitung umgehend einstellen. In diesem Fall ist die Angabe einer besonderen Situation nicht erforderlich. Dies gilt auch für das Profiling, soweit es mit solcher Direktwerbung in Verbindung steht."





        )
      ), " "
      , React.createElement('p', {__self: this, __source: {fileName: _jsxFileName, lineNumber: 290}}
        , React.createElement('b', {__self: this, __source: {fileName: _jsxFileName, lineNumber: 291}}, "Möchten Sie von Ihrem Widerspruchsrecht Gebrauch machen, genügt eine E-Mail an "


          , React.createElement('a', { href: "mailto:datenschutz@zv.fraunhofer.de", target: "_blank", __self: this, __source: {fileName: _jsxFileName, lineNumber: 294}}, "datenschutzkoordination@zv.fraunhofer.de"

          ), "."

        )
      )
      , React.createElement('p', {__self: this, __source: {fileName: _jsxFileName, lineNumber: 300}}, " ")
      , React.createElement('h2', {__self: this, __source: {fileName: _jsxFileName, lineNumber: 301}}
        , React.createElement('a', { id: "6", __self: this, __source: {fileName: _jsxFileName, lineNumber: 302}}, "6 Datensicherheit" )
      )
      , React.createElement('p', {__self: this, __source: {fileName: _jsxFileName, lineNumber: 304}}, "Alle von Ihnen persönlich übermittelten Daten werden mit dem allgemein üblichen und sicheren Standard TLS (Transport Layer Security) verschlüsselt übertragen. TLS ist ein sicherer und erprobter Standard, der z.B. auch beim Onlinebanking Verwendung findet. Sie erkennen eine sichere TLS-Verbindung unter anderem an dem angehängten s am http (also https://..) in der Adressleiste Ihres Browsers oder am Schloss-Symbol im unteren Bereich Ihres Browsers."







      )
      , React.createElement('p', {__self: this, __source: {fileName: _jsxFileName, lineNumber: 313}}, "Wir bedienen uns im Übrigen geeigneter technischer und organisatorischer Sicherheitsmaßnahmen, um Ihre Daten gegen zufällige oder vorsätzliche Manipulationen, teilweisen oder vollständigen Verlust, Zerstörung oder gegen den unbefugten Zugriff Dritter zu schützen. Unsere Sicherheitsmaßnahmen werden entsprechend der technologischen Entwicklung fortlaufend verbessert."






      )
      , React.createElement('h2', {__self: this, __source: {fileName: _jsxFileName, lineNumber: 321}}
        , React.createElement('a', { id: "7", __self: this, __source: {fileName: _jsxFileName, lineNumber: 322}}, "7 Aktualität und Änderung dieser Datenschutzinformation"     )
      )
      , React.createElement('p', {__self: this, __source: {fileName: _jsxFileName, lineNumber: 324}}, "Durch die Weiterentwicklung unserer Webseite und Angebote darüber oder aufgrund geänderter gesetzlicher bzw. behördlicher Vorgaben kann es notwendig werden, diese Datenschutzinformation zu ändern. Die jeweils aktuelle Datenschutzinformation kann jederzeit auf der Webseite von Ihnen abgerufen und ausgedruckt werden."





      )
      , React.createElement('p', {__self: this, __source: {fileName: _jsxFileName, lineNumber: 331}}, " ")
      , React.createElement('p', {__self: this, __source: {fileName: _jsxFileName, lineNumber: 332}}, " ")
      , React.createElement('p', {__self: this, __source: {fileName: _jsxFileName, lineNumber: 333}}, " ")
    )
  );
});

import { makeAutoObservable } from "mobx";



export class UiService {
  

  __init() {this.notifications = []}

  constructor(app) {;UiService.prototype.__init.call(this);
    makeAutoObservable(this);

    this.app = app;
  }

   async init(cache) {
    Object.assign(this, cache);
  }

  notification(message) {}
}

const _jsxFileName = "/usr/src/app/app/js/components/AdminHomeLayout.tsx";import React from "react";

import { Grid, MenuItem, MenuList, Paper } from "@material-ui/core";
import { useNavigate } from "react-router-dom";

const LINKS = [
  {
    url: "/admin/users",
    label: "Benutzer",
  },
  {
    url: "/admin/checks",
    label: "KLW-Checks",
  },
  {
    url: "/admin/contacts",
    label: "Kontakte",
  },
  {
    url: "/admin/question",
    label: "Fragen",
  },
  {
    url: "/admin/category",
    label: "Kategorien",
  },
  {
    url: "/admin/industry",
    label: "Branchen",
  },
  {
    url: "/admin/region",
    label: "Regionen",
  },
];

export const AdminHomeLayout = React.memo(function AdminHomeLayout({
  children,
}) {
  const navigate = useNavigate();

  return (
    React.createElement(Grid, { container: true, spacing: 2, __self: this, __source: {fileName: _jsxFileName, lineNumber: 43}}
      , React.createElement(Grid, { item: true, md: 4, xs: 12, __self: this, __source: {fileName: _jsxFileName, lineNumber: 44}}
        , React.createElement(Paper, {__self: this, __source: {fileName: _jsxFileName, lineNumber: 45}}
          , React.createElement(MenuList, {__self: this, __source: {fileName: _jsxFileName, lineNumber: 46}}
            , LINKS.map(({ url, label }) => (
              React.createElement(MenuItem, {
                key: url,
                button: true,
                selected: window.location.pathname.startsWith(url),
                onClick: () => {
                  navigate(url);
                }, __self: this, __source: {fileName: _jsxFileName, lineNumber: 48}}

                , label
              )
            ))
          )
        )
      )
      , React.createElement(Grid, { item: true, md: 8, xs: 12, __self: this, __source: {fileName: _jsxFileName, lineNumber: 62}}
        , React.createElement(Paper, {__self: this, __source: {fileName: _jsxFileName, lineNumber: 63}}, children)
      )
    )
  );
});

const _jsxFileName = "/usr/src/app/app/js/components/AdminHomeRoute.tsx"; function _optionalChain(ops) { let lastAccessLHS = undefined; let value = ops[0]; let i = 1; while (i < ops.length) { const op = ops[i]; const fn = ops[i + 1]; i += 2; if ((op === 'optionalAccess' || op === 'optionalCall') && value == null) { return undefined; } if (op === 'access' || op === 'optionalAccess') { lastAccessLHS = value; value = fn(value); } else if (op === 'call' || op === 'optionalCall') { value = fn((...args) => value.call(lastAccessLHS, ...args)); lastAccessLHS = undefined; } } return value; }import React from "react";
import { observer } from "mobx-react-lite";
import { Outlet } from "react-router-dom";

import { useApp } from "../context";

import { ErrorMessage } from "./ErrorMessage";
import { AdminHomeLayout } from "./AdminHomeLayout";

export const AdminHomeRoute = observer(function AdminHomeRoute({ children }) {
  const app = useApp();

  // TODO: Check auth/permission

  React.useEffect(() => {
    app.admin.init();
  }, []);

  if (!_optionalChain([app, 'access', _ => _.parse, 'access', _2 => _2.user, 'optionalAccess', _3 => _3.isAdmin])) {
    return (
      React.createElement(ErrorMessage, {
        icon: "fa:exclamation-circle",
        title: "Berechtigungs Fehler" ,
        message: "Du musst Admin Nutzer sein, um diese Seite zu sehen."         , __self: this, __source: {fileName: _jsxFileName, lineNumber: 21}}
      )
    );
  }

  return (
    React.createElement(AdminHomeLayout, {__self: this, __source: {fileName: _jsxFileName, lineNumber: 30}}
      , React.createElement(Outlet, {__self: this, __source: {fileName: _jsxFileName, lineNumber: 31}} )
    )
  );
});

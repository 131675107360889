const _jsxFileName = "/usr/src/app/app/js/components/BenchmarkEditFinishRoute.tsx"; function _optionalChain(ops) { let lastAccessLHS = undefined; let value = ops[0]; let i = 1; while (i < ops.length) { const op = ops[i]; const fn = ops[i + 1]; i += 2; if ((op === 'optionalAccess' || op === 'optionalCall') && value == null) { return undefined; } if (op === 'access' || op === 'optionalAccess') { lastAccessLHS = value; value = fn(value); } else if (op === 'call' || op === 'optionalCall') { value = fn((...args) => value.call(lastAccessLHS, ...args)); lastAccessLHS = undefined; } } return value; }import React, { useEffect, useState } from "react";

import { observer } from "mobx-react-lite";
import { useApp } from "../context";

import { useNavigate } from "react-router-dom";
import {
  Button,
  FormControl,
  Grid,
  makeStyles,
  Paper,
  TextField,
  Typography,
} from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  formControl: {
    width: "100%",
    marginBottom: theme.spacing(2),
  },
  text: {
    marginBottom: theme.spacing(2),
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
  button: {
    marginRight: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
  padding: {
    padding: theme.spacing(2),
  },
}));

const FIELDS = [
  {
    key: "contact_name",
    label: "Ihr Vorname",
    required: true,
  },
  {
    key: "contact_family_name",
    label: "Ihr Nachnname",
    required: true,
  },
  {
    key: "contact_position",
    label: "Ihre Abteilung im Unternehmen",
    required: true,
  },
];

const getFormattedDateTime = () => {
  const now = new Date();
  const year = now.getFullYear();
  const month = String(now.getMonth() + 1).padStart(2, "0");
  const day = String(now.getDate()).padStart(2, "0");
  const hours = String(now.getHours()).padStart(2, "0");
  const minutes = String(now.getMinutes()).padStart(2, "0");
  const seconds = String(now.getSeconds()).padStart(2, "0");

  return `${year}${month}${day}-${hours}${minutes}${seconds}`;
};

export const BenchmarkEditFinishRoute = observer(
  function BenchmarkEditFinishRoute() {
    const app = useApp();
    const classes = useStyles();
    const navigate = useNavigate();

    const [formValues, setFormValues] = useState(() => {
      const initialValues = {};
      FIELDS.forEach((field) => {
        initialValues[field.key] = _optionalChain([app, 'access', _ => _.benchmarks, 'access', _2 => _2.benchmark, 'optionalAccess', _3 => _3[field.key]]) || "";
      });
      return initialValues;
    });

    const [isFormValid, setIsFormValid] = useState(false);

    useEffect(() => {
      const allFieldsFilled = FIELDS.every(
        (field) =>
          !field.required ||
          (formValues[field.key] && formValues[field.key].trim() !== "")
      );
      setIsFormValid(allFieldsFilled);
    }, [formValues]);

    const handleChange = (key, value) => {
      setFormValues((prevValues) => {
        const newValues = {
          ...prevValues,
          [key]: value,
        };

        if (key === "contact_position") {
          newValues.alt_name = `KLW-Check_${value}_${getFormattedDateTime()}`;
        }

        return newValues;
      });

      app.benchmarks.updateCurrentBenchmark(key, value);
    };

    useEffect(() => {
      app.benchmarks.updateCurrentBenchmark("alt_name", formValues.alt_name);
    }, [app.benchmarks, formValues.alt_name]);

    return (
      React.createElement(Paper, { className: classes.padding, __self: this, __source: {fileName: _jsxFileName, lineNumber: 114}}
        , React.createElement(Grid, { container: true, justifyContent: "center", spacing: 1, __self: this, __source: {fileName: _jsxFileName, lineNumber: 115}}
          , React.createElement(Grid, { item: true, xs: 12, __self: this, __source: {fileName: _jsxFileName, lineNumber: 116}}
            , React.createElement('div', { className: classes.formControl, __self: this, __source: {fileName: _jsxFileName, lineNumber: 117}}
              , React.createElement(Typography, { component: "h1", variant: "h5", __self: this, __source: {fileName: _jsxFileName, lineNumber: 118}}, "Informationen zu diesem Kreislaufwirtschafts-Check"

              )

              , React.createElement(Typography, { variant: "body1", className: classes.text, __self: this, __source: {fileName: _jsxFileName, lineNumber: 122}}, "Um diesen Kreislaufwirtschafts-Check anzulegen, werden folgende Informationen benötigt."


              )
            )
          )

          , React.createElement(Grid, { item: true, xs: 12, __self: this, __source: {fileName: _jsxFileName, lineNumber: 129}}
            , FIELDS.map((field, i) => (
              React.createElement(FormControl, {
                key: field.key,
                variant: "outlined",
                className: classes.formControl, __self: this, __source: {fileName: _jsxFileName, lineNumber: 131}}

                , React.createElement(TextField, {
                  variant: "outlined",
                  required: field.required,
                  fullWidth: true,
                  label: field.label,
                  defaultValue: _optionalChain([app, 'access', _4 => _4.benchmarks, 'access', _5 => _5.benchmark, 'optionalAccess', _6 => _6[field.key]]) ,
                  onChange: (e) => handleChange(field.key, e.target.value), __self: this, __source: {fileName: _jsxFileName, lineNumber: 136}}
                )
              )
            ))

            , React.createElement(Button, {
              className: classes.button,
              variant: "contained",
              color: "primary",
              onClick: () => {
                if (isFormValid) {
                  navigate("/check/edit");
                }
              },
              disabled: !isFormValid, __self: this, __source: {fileName: _jsxFileName, lineNumber: 147}}
, "KLW-Check anlegen"

            )
          )
        )
      )
    );
  }
);

const _jsxFileName = "/usr/src/app/app/js/components/App.tsx";import React from "react";

import { BrowserRouter as ReactRouter } from "react-router-dom";
import { SnackbarProvider } from "notistack";

import { AppContextProvider } from "../context";
import { Router } from "./Router";
import { Layout } from "./Layout";
import { ThemeProvider } from "./ThemeProvider";
import { AppLoadingScreen } from "./AppLoadingScreen";




export function App({}) {
  return (
    React.createElement(AppContextProvider, {__self: this, __source: {fileName: _jsxFileName, lineNumber: 17}}
      , React.createElement(ThemeProvider, {__self: this, __source: {fileName: _jsxFileName, lineNumber: 18}}
        , React.createElement(SnackbarProvider, {
          maxSnack: 2,
          anchorOrigin: {
            vertical: "bottom",
            horizontal: "right",
          }, __self: this, __source: {fileName: _jsxFileName, lineNumber: 19}}

          /* <NotificationProvider /> */
          , React.createElement(AppLoadingScreen, {__self: this, __source: {fileName: _jsxFileName, lineNumber: 27}}
            , React.createElement(ReactRouter, {__self: this, __source: {fileName: _jsxFileName, lineNumber: 28}}
              , React.createElement(Layout, {__self: this, __source: {fileName: _jsxFileName, lineNumber: 29}}
                , React.createElement(Router, {__self: this, __source: {fileName: _jsxFileName, lineNumber: 30}} )
              )
            )
          )
        )
      )
    )
  );
}

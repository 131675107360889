export class ParseError extends Error {
  
  

  constructor(code, message) {
    super(message);

    this._parse_code = code;
    this._parse_message = message;
  }
}

const _jsxFileName = "/usr/src/app/app/js/helper/AnimatedProgressProvider.ts";import React, { useState, useEffect } from "react";
import { Animate } from "react-move";

const AnimatedProgressProvider = (props ) => {
  const [isAnimated, setIsAnimated] = useState(false);

  const { valueStart, valueEnd, duration, easingFunction, repeat, children } = props;

  useEffect(() => {
    let interval;

    if (repeat) {
      interval = window.setInterval(() => {
        setIsAnimated((prevIsAnimated) => !prevIsAnimated);
      }, duration * 1000);
    } else {
      setIsAnimated(true);
    }

    return () => {
      window.clearInterval(interval);
    };
  }, [duration, repeat]);

  return (
    React.createElement(Animate, {
      start: () => ({
        value: valueStart
      }),
      update: () => ({
        value: [isAnimated ? valueEnd : valueStart],
        timing: {
          duration: duration * 1000,
          ease: easingFunction
        }
      }), __self: this, __source: {fileName: _jsxFileName, lineNumber: 26}}

      , ({ value }) => children(value)
    )
  );
};

AnimatedProgressProvider.defaultProps = {
  valueStart: 0
};

export default AnimatedProgressProvider;
const _jsxFileName = "/usr/src/app/app/js/components/BenchmarkResumeRoute.tsx";import React from "react";
import { observer } from "mobx-react-lite";
import { useApp } from "../context";
import { CircularProgress } from "@material-ui/core";
import styled from "styled-components";
import { useNavigate, useParams } from "react-router-dom";

const Container = styled.div`
  padding: 50px;

  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
`;

export const BenchmarkResumeRoute = observer(function BenchmarkResumeRoute({
  children,
}) {
  const app = useApp();
  const { id, secret } = useParams();
  const navigate = useNavigate();

  const [errState, setErrState] = React.useState(false);

  React.useEffect(() => {
    if (id && secret) {
      setErrState(false);
      app.benchmarks
        .resumeBenchmark(id, secret)
        .then(() => {
          navigate("/check/edit");
        })
        .catch((e) => {
          setErrState(true);
        });
    }
  }, [id, secret]);

  return (
    React.createElement(Container, {__self: this, __source: {fileName: _jsxFileName, lineNumber: 42}}
      , React.createElement(CircularProgress, {__self: this, __source: {fileName: _jsxFileName, lineNumber: 43}} )
    )
  );
});

const _jsxFileName = "/usr/src/app/app/js/context/AppContextProvider.tsx";import * as React from "react";

import { AppContext } from "./index";
import { AppService } from "../services/AppService";

export const AppContextProvider = React.memo(({ children }) => {
  const app = React.useMemo(() => new AppService(), []);

  return React.createElement(AppContext.Provider, { value: app, __self: this, __source: {fileName: _jsxFileName, lineNumber: 9}}, children);
});

const _jsxFileName = "/usr/src/app/app/js/components/LoginRoute.tsx";import React from "react";
import { observer } from "mobx-react-lite";
import { useApp } from "../context";
import { ErrorMessage } from "./ErrorMessage";
import { Button, TextField, Typography } from "@material-ui/core";
import { useNavigate } from "react-router-dom";

export const LoginRoute = observer(function LoginRoute() {
  const app = useApp();
  const navigate = useNavigate();

  const [state, setState] = React.useState({ email: "", password: "" });

  if (app.parse.user) {
    return (
      React.createElement(ErrorMessage, {
        icon: "fa:exclamation-circle",
        title: "Bereits Eingeloggt" ,
        message: "", __self: this, __source: {fileName: _jsxFileName, lineNumber: 16}}
      )
    );
  }

  return (
    React.createElement('div', { style: { width: 400, margin: "0 auto" }, __self: this, __source: {fileName: _jsxFileName, lineNumber: 25}}
      , React.createElement(Typography, { component: "h1", variant: "h5", align: "center", __self: this, __source: {fileName: _jsxFileName, lineNumber: 26}}, "Admin Login"

      )
      , React.createElement('form', {
        noValidate: true,
        onSubmit: function handleSubmit(event) {
          event.preventDefault();

          app.parse.login(state.email, state.password).then(
            () => {
              navigate("/admin");
            },
            (error) => {
              app.notifications.error(error.message);
            }
          );
        }, __self: this, __source: {fileName: _jsxFileName, lineNumber: 29}}

        , React.createElement(TextField, {
          variant: "outlined",
          margin: "normal",
          required: true,
          fullWidth: true,
          id: "email",
          label: "E-Mail Adresse" ,
          name: "email",
          autoComplete: "email",
          autoFocus: true,
          onChange: (e) => {
            setState((cur) => ({ ...cur, email: e.target.value }));
          }, __self: this, __source: {fileName: _jsxFileName, lineNumber: 44}}
        )
        , React.createElement(TextField, {
          variant: "outlined",
          margin: "normal",
          required: true,
          fullWidth: true,
          name: "password",
          label: "Passwort",
          type: "password",
          id: "password",
          autoComplete: "current-password",
          onChange: (e) => {
            setState((cur) => ({ ...cur, password: e.target.value }));
          }, __self: this, __source: {fileName: _jsxFileName, lineNumber: 58}}
        )
        , React.createElement(Button, { type: "submit", fullWidth: true, variant: "contained", color: "primary", __self: this, __source: {fileName: _jsxFileName, lineNumber: 72}}, "Anmelden"

        )
      )
    )
  );
});

 function _optionalChain(ops) { let lastAccessLHS = undefined; let value = ops[0]; let i = 1; while (i < ops.length) { const op = ops[i]; const fn = ops[i + 1]; i += 2; if ((op === 'optionalAccess' || op === 'optionalCall') && value == null) { return undefined; } if (op === 'access' || op === 'optionalAccess') { lastAccessLHS = value; value = fn(value); } else if (op === 'call' || op === 'optionalCall') { value = fn((...args) => value.call(lastAccessLHS, ...args)); lastAccessLHS = undefined; } } return value; }import { makeAutoObservable } from "mobx";












export class AdminService {
  

  __init() {this.users = []}

  constructor(app) {;AdminService.prototype.__init.call(this);
    makeAutoObservable(this);

    this.app = app;
  }

   get isAdmin() {
    return !!_optionalChain([this, 'access', _ => _.app, 'access', _2 => _2.parse, 'access', _3 => _3.user, 'optionalAccess', _4 => _4.isAdmin]);
  }

   async init(cache = {}) {
    Object.assign(this, cache);

    if (!this.isAdmin) {
      return;
    }

    this.users = (
      await this.app.parse.get("/classes/_User?order=name")
    ).results;
  }

   updateHasChanges(a, b) {
    for (const [key, value] of Object.entries(b)) {
      if (a[key] !== value) {
        return true;
      }
    }

    return false;
  }

   async updateUser(id, data) {
    const user = this.users.find((u) => u.objectId === id);

    if (!user || !this.updateHasChanges(user, data)) {
      return;
    }

    if ("password" in data && !data.password) {
      return;
    }

    await this.app.parse.put(`/classes/_User/${id}`, data);

    Object.assign(user, await this.app.parse.get(`/classes/_User/${id}`));

    this.app.notifications.success("Wert wurde geändert");
  }

   async updateQuestion(id, data) {
    const question = this.app.benchmarks.questions.find(
      (question) => question.objectId === id
    );

    if (!question || !this.updateHasChanges(question, data)) {
      return;
    }

    await this.app.parse.put(`/classes/Question/${id}`, data);

    Object.assign(question, data);

    this.app.notifications.success("Wert wurde geändert");
  }

   async updateCategory(id, data) {
    const category = this.app.benchmarks.categories.find(
      (category) => category.objectId === id
    );

    if (!category || !this.updateHasChanges(category, data)) {
      return;
    }

    await this.app.parse.put(`/classes/Category/${id}`, data);

    Object.assign(category, data);

    this.app.notifications.success("Wert wurde geändert");
  }

   async updateDimension(id, data) {
    const dimension = this.app.benchmarks.dimensions.find(
      (dimension) => dimension.objectId === id
    );

    if (!dimension || !this.updateHasChanges(dimension, data)) {
      return;
    }

    await this.app.parse.put(`/classes/Dimension/${id}`, data);

    Object.assign(dimension, data);

    this.app.notifications.success("Wert wurde geändert");
  }

   async updateIndustry(
    id,
    key,
    value
  ) {
    const data = {
      [key]: value,
    };

    const industry = this.app.benchmarks.industries.find(
      (industry) => industry.objectId === id
    );

    if (!industry || industry[key] === value) {
      return;
    }

    await this.app.parse.put(`/classes/Industry/${id}`, data);

    Object.assign(industry, data);

    this.app.notifications.success("Wert wurde geändert");
  }

   async updateRegion(
    id,
    key,
    value
  ) {
    const data = {
      [key]: value,
    };

    const region = this.app.benchmarks.regions.find(
      (region) => region.objectId === id
    );

    if (!region || region[key] === value) {
      return;
    }

    await this.app.parse.put(`/classes/Region/${id}`, data);

    Object.assign(region, data);

    this.app.notifications.success("Wert wurde geändert");
  }

   async create(className) {
    if (className === "_User") {
      return this.createUserAdmin();
    }

    const response = await this.app.parse.post(`/classes/${className}`, {});

    await this.app.benchmarks.init({});

    this.app.notifications.success("Element wurde erstellt");

    return response.objectId;
  }

   async createUserAdmin() {
    const response = await this.app.parse.post(`/classes/_User`, {
      name: "Neuer Benutzer",
      username: "-",
      email: "1@web.de",
      companyName: "Muster GmbH",
      password: Math.random().toString(),
    });

    await this.init();

    this.app.notifications.success("Element wurde erstellt");

    return response.objectId;
  }

   async createUser(
    companyName,
    email,
    password
  ) {
    const response = await this.app.parse.post(`/classes/_User`, {
      companyName: companyName,
      username: "-",
      email: email,
      password: password,
    });

    await this.init();

    this.app.notifications.success("Element wurde erstellt");

    return response.objectId;
  }

   async delete(className, id) {
    if (window.confirm("Sicher dass das Element gelöscht werden soll?")) {
      const response = await this.app.parse.delete(
        `/classes/${className}/${id}`
      );

      if (className === "_User") {
        await this.init();
      } else {
        await this.app.benchmarks.init({});
      }

      this.app.notifications.success("Element wurde gelöscht");
    }
  }

   async downloadContacts() {
    const response = await this.app.parse.cc("export-contacts");

    this.app.notifications.success("Kontakte werden heruntergeladen");

    this.exportToCsv("benchmarks.csv", response.result);
  }

   async downloadBenchmarks() {
    const response = await this.app.parse.cc("export-benchmarks");

    this.app.notifications.success("Benchmarks werden heruntergeladen");

    this.exportToCsv("benchmarks.csv", response.result);
  }

   async exportToCsv(filename, rows) {
    const csvFile = rows
      .map((row) =>
        row
          .map((col) =>
            col == null
              ? ""
              : col instanceof Date
              ? col.toLocaleString()
              : col.toString()
          )
          .map((col) => col.replace(/"/g, '""'))
          .map((col) => (col.search(/("|;|\n)/g) >= 0 ? '"' + col + '"' : col))
          .join(";")
      )
      .join("\n");

    var blob = new Blob(["\ufeff" + csvFile], {
      type: "text/csv;charset=utf-8;",
    });
    if (navigator.msSaveBlob) {
      // IE 10+
      navigator.msSaveBlob(blob, filename);
    } else {
      const link = document.createElement("a");
      if (link.download !== undefined) {
        // feature detection
        // Browsers that support HTML5 download attribute
        const url = URL.createObjectURL(blob);
        link.setAttribute("href", url);
        link.setAttribute("download", filename);
        link.style.visibility = "hidden";
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      }
    }
  }
}

const _jsxFileName = "/usr/src/app/app/js/components/AdminIndustryEditRoute.tsx"; function _optionalChain(ops) { let lastAccessLHS = undefined; let value = ops[0]; let i = 1; while (i < ops.length) { const op = ops[i]; const fn = ops[i + 1]; i += 2; if ((op === 'optionalAccess' || op === 'optionalCall') && value == null) { return undefined; } if (op === 'access' || op === 'optionalAccess') { lastAccessLHS = value; value = fn(value); } else if (op === 'call' || op === 'optionalCall') { value = fn((...args) => value.call(lastAccessLHS, ...args)); lastAccessLHS = undefined; } } return value; }import React from "react";
import { observer } from "mobx-react-lite";
import { useParams } from "react-router-dom";
import { TextField } from "@material-ui/core";

import { useApp } from "../context";

export const AdminIndustryEditRoute = observer(
  function AdminIndustryEditRoute() {
    const app = useApp();

    const { id } = useParams();

    return (
      React.createElement(React.Fragment, null
        , React.createElement(TextField, {
          variant: "outlined",
          margin: "normal",
          fullWidth: true,
          label: "Name",
          defaultValue: 
            _optionalChain([app, 'access', _ => _.benchmarks, 'access', _2 => _2.industries, 'access', _3 => _3.find, 'call', _4 => _4((o) => o.objectId === id), 'optionalAccess', _5 => _5.name]) || ""
          ,
          onBlur: (e) => {
            app.admin.updateIndustry(id, "name", e.target.value);
          }, __self: this, __source: {fileName: _jsxFileName, lineNumber: 16}}
        )
      )
    );
  }
);

const _jsxFileName = "/usr/src/app/app/js/components/AdminList.tsx";import React from "react";
import { observer } from "mobx-react-lite";
import { useNavigate, useOutlet } from "react-router-dom";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Grid,
  List,
  ListItem,
  ListItemSecondaryAction,
  ListItemText,
  makeStyles,
  Typography,
} from "@material-ui/core";

import { useApp } from "../context";

const useStyles = makeStyles((theme) => ({
  padding: {
    padding: theme.spacing(2),
  },
  createButton: {
    float: "right",
  },
  listItem: {
    paddingRight: 220,
  },
}));








export const AdminList = observer(function AdminList({
  title,
  className,
  navName,
  rows,
}) {
  const app = useApp();
  const classes = useStyles();
  const navigate = useNavigate();
  const outlet = useOutlet();

  // TODO: Check auth/permission

  const onCreate = () => {
    app.admin.create(className).then((id) => {
      navigate(`/admin/${navName}/edit/${id}`);
    });
  };

  return (
    React.createElement(Grid, { container: true, spacing: 2, __self: this, __source: {fileName: _jsxFileName, lineNumber: 61}}
      , React.createElement(Grid, { item: true, xs: 12, __self: this, __source: {fileName: _jsxFileName, lineNumber: 62}}
        , React.createElement(Typography, { variant: "h5", component: "h2", className: classes.padding, __self: this, __source: {fileName: _jsxFileName, lineNumber: 63}}
          , React.createElement(Button, {
            variant: "contained",
            size: "small",
            color: "primary",
            onClick: onCreate,
            className: classes.createButton, __self: this, __source: {fileName: _jsxFileName, lineNumber: 64}}
, "Neu"

          )
          , title, " Verwaltung"
        )
      )

      , React.createElement(Grid, { item: true, xs: 12, __self: this, __source: {fileName: _jsxFileName, lineNumber: 77}}
        , React.createElement(List, {__self: this, __source: {fileName: _jsxFileName, lineNumber: 78}}
          , rows.map((o) => {
            const onEdit = () => {
              navigate(`/admin/${navName}/edit/${o.objectId}`);
            };

            const onDelete = () => {
              app.admin.delete(className, o.objectId);
            };

            return (
              React.createElement(ListItem, {
                key: o.objectId,
                button: true,
                onClick: onEdit,
                className: classes.listItem, __self: this, __source: {fileName: _jsxFileName, lineNumber: 89}}

                , React.createElement(ListItemText, { primary: o.name, __self: this, __source: {fileName: _jsxFileName, lineNumber: 95}} )
                , React.createElement(ListItemSecondaryAction, {__self: this, __source: {fileName: _jsxFileName, lineNumber: 96}}
                  , React.createElement(Button, {
                    variant: "outlined",
                    size: "small",
                    color: "primary",
                    onClick: onEdit, __self: this, __source: {fileName: _jsxFileName, lineNumber: 97}}
, "Bearbeiten"

                  ), " "
                  , React.createElement(Button, {
                    variant: "outlined",
                    size: "small",
                    color: "primary",
                    onClick: onDelete, __self: this, __source: {fileName: _jsxFileName, lineNumber: 105}}
, "Löschen"

                  )
                )
              )
            );
          })
        )
      )

      , React.createElement(Dialog, { open: !!outlet, onClose: () => navigate(`/admin/${navName}`), __self: this, __source: {fileName: _jsxFileName, lineNumber: 120}}
        , React.createElement(DialogTitle, {__self: this, __source: {fileName: _jsxFileName, lineNumber: 121}}, title, " bearbeiten" )
        , React.createElement(DialogContent, {__self: this, __source: {fileName: _jsxFileName, lineNumber: 122}}
          , React.createElement(DialogContentText, { style: { marginBottom: 30 }, __self: this, __source: {fileName: _jsxFileName, lineNumber: 123}}, "Änderungen werden sofort übernommen und müssen nicht extra gespeichert werden."


          )

          , outlet
        )
        , React.createElement(DialogActions, {__self: this, __source: {fileName: _jsxFileName, lineNumber: 130}}
          , React.createElement(Button, {
            onClick: () => {
              navigate(`/admin/${navName}`);
            },
            color: "primary", __self: this, __source: {fileName: _jsxFileName, lineNumber: 131}}
, "Fertig"

          )
        )
      )
    )
  );
});

const _jsxFileName = "/usr/src/app/app/js/components/EvaluationChart.tsx";import { observer } from "mobx-react-lite";
import React from "react";
import { Radar } from "react-chartjs-2";
import {
  Chart as ChartJS,
  LineElement,
  PointElement,
  Tooltip,
  Legend,
  RadialLinearScale,
  Filler,
} from "chart.js";

ChartJS.register(
  LineElement,
  PointElement,
  Tooltip,
  Legend,
  RadialLinearScale,
  Filler
);










export const EvaluationChart = observer(function EvaluationChart({
  labels,
  evaluation,
  // checkedOtherCompany,
  // checkedSameBranch,
  // evaluationOtherCompany,
  // evaluationSameBranch,
}) {
  const objCompany = {
    label: "In diesem KLW-Check",
    data: evaluation,
    fill: true,
    backgroundColor: "rgba(205, 10, 30, 0.3)",
    borderColor: "rgb(205, 10, 30)",
    pointBackgroundColor: "rgb(255, 99, 132)",
    pointBorderColor: "#fff",
    pointHoverBackgroundColor: "#fff",
    pointHoverBorderColor: "rgb(255, 99, 132)",
  };

  // const objOtherCompany = {
  //   label: "Andere Unternehmen",
  //   data: evaluationOtherCompany,
  //   fill: true,
  //   backgroundColor: "rgba(0, 123, 196, 0.3)",
  //   borderColor: "rgb(0, 123, 196)",
  //   pointBackgroundColor: "rgb(0, 123, 196)",
  //   pointBorderColor: "#fff",
  //   pointHoverBackgroundColor: "#fff",
  //   pointHoverBorderColor: "rgb(0, 123, 196)",
  // };

  // const objSameBranch = {
  //   label: "Andere Unternehmen mit gleicher Branche",
  //   data: evaluationSameBranch,
  //   fill: true,
  //   backgroundColor: "rgba(124, 184, 192, 0.3)",
  //   borderColor: "rgb(124, 184, 192)",
  //   pointBackgroundColor: "rgb(124, 184, 192)",
  //   pointBorderColor: "#fff",
  //   pointHoverBackgroundColor: "#fff",
  //   pointHoverBorderColor: "rgb(124, 184, 192)",
  // };

  const datasets = [objCompany];
  // if (checkedOtherCompany) {
  //   datasets.push(objOtherCompany);
  // }
  // if (checkedSameBranch) {
  //   datasets.push(objSameBranch);
  // }

  const data = {
    labels: labels,
    datasets: datasets,
  };

  const options = {
    maintainAspectRatio: false,
    responsive: true,
    elements: {
      line: {
        borderWidth: 3,
      },
    },
    scale: {
      min: 0,
      max: 4,
      step: 1,
      ticks: {
        stepSize: 1,
      },
    },
    plugins: {
      legend: {
        display: false,
      },
    },
    scales: {
      r: {
        pointLabels: {
          font: {
            size: 16,
          },
        },
      },
    },
  };

  return React.createElement(Radar, { data: data, options: options, __self: this, __source: {fileName: _jsxFileName, lineNumber: 121}} );
});

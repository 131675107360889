import { makeAutoObservable } from "mobx";





export class NotificationService {
  

  __init() {this.notifications = []}

  constructor(app) {;NotificationService.prototype.__init.call(this);
    makeAutoObservable(this);

    this.app = app;
  }

   async init(cache) {
    // Object.assign(this, cache);
  }

  success(message) {
    this.notifications.push({ message, options: { variant: "success" } });
  }

  error(message) {
    this.notifications.push({ message, options: { variant: "error" } });
  }

  warning(message) {
    this.notifications.push({ message, options: { variant: "warning" } });
  }

  info(message) {
    this.notifications.push({ message, options: { variant: "info" } });
  }

  toJSON() {
    return undefined;
  }
}

const _jsxFileName = "/usr/src/app/app/js/components/AdminUserEditRoute.tsx"; function _optionalChain(ops) { let lastAccessLHS = undefined; let value = ops[0]; let i = 1; while (i < ops.length) { const op = ops[i]; const fn = ops[i + 1]; i += 2; if ((op === 'optionalAccess' || op === 'optionalCall') && value == null) { return undefined; } if (op === 'access' || op === 'optionalAccess') { lastAccessLHS = value; value = fn(value); } else if (op === 'call' || op === 'optionalCall') { value = fn((...args) => value.call(lastAccessLHS, ...args)); lastAccessLHS = undefined; } } return value; }import React from "react";
import { observer } from "mobx-react-lite";
import { useParams } from "react-router-dom";
import { Checkbox, FormControlLabel, TextField } from "@material-ui/core";

import { useApp } from "../context";

export const AdminUserEditRoute = observer(function AdminUserEditRoute() {
  const app = useApp();

  const { id } = useParams();

  const user = app.admin.users.find((o) => o.objectId === id);

  return (
    React.createElement(React.Fragment, null
      , React.createElement(TextField, {
        variant: "outlined",
        margin: "normal",
        fullWidth: true,
        label: "Email",
        defaultValue: _optionalChain([user, 'optionalAccess', _ => _.email]) || "",
        onBlur: (e) => {
          app.admin.updateUser(id, { email: e.target.value });
        }, __self: this, __source: {fileName: _jsxFileName, lineNumber: 17}}
      )

      , React.createElement(TextField, {
        variant: "outlined",
        margin: "normal",
        fullWidth: true,
        label: "Passwort",
        type: "password",
        autoComplete: "off",
        helperText: "Bitte frei lassen, wenn das Passwort nicht geändert werden soll. Wenn du dein eigenes Passwort änderst, musst du dich danach neu anmelden. Das gleiche gilt für andere Nutzer."                           ,
        defaultValue: _optionalChain([user, 'optionalAccess', _2 => _2.password]) || "",
        onBlur: (e) => {
          app.admin.updateUser(id, { password: e.target.value });
        }, __self: this, __source: {fileName: _jsxFileName, lineNumber: 28}}
      )

      , React.createElement(FormControlLabel, {
        control: 
          React.createElement(Checkbox, {
            color: "primary",
            checked: !!_optionalChain([user, 'optionalAccess', _3 => _3.isAdmin]),
            onChange: (e) => {
              app.admin.updateUser(id, { isAdmin: e.target.checked });
            }, __self: this, __source: {fileName: _jsxFileName, lineNumber: 44}}
          )
        ,
        label: "Administrator", __self: this, __source: {fileName: _jsxFileName, lineNumber: 42}}
      )

      , React.createElement(FormControlLabel, {
        control: 
          React.createElement(Checkbox, {
            color: "primary",
            checked: !!_optionalChain([user, 'optionalAccess', _4 => _4.notifications]),
            onChange: (e) => {
              app.admin.updateUser(id, { notifications: e.target.checked });
            }, __self: this, __source: {fileName: _jsxFileName, lineNumber: 57}}
          )
        ,
        label: "Benchmark Notifikationen" , __self: this, __source: {fileName: _jsxFileName, lineNumber: 55}}
      )
    )
  );
});

const _jsxFileName = "/usr/src/app/app/js/components/FooterContent.tsx";import React from "react";
import { observer } from "mobx-react-lite";
import { useApp } from "../context";

import styled from "styled-components";
import { Link } from "react-router-dom";

const Footer = styled.div`
  height: 90px;
  background: white;
  border-top: 1px solid #ebebeb;
  user-select: none;

  overflow: hidden;

  position: relative;

  z-index: 3;
`;

const Container = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
  max-width: 1300px;
  margin: 0 auto;
  padding: 0 20px;
  padding-top: 10px;
`;

const FooterInfo = styled.div`
  display: flex;
  flex-direction: column;
`;

const Info = styled.div`
  display: block;
  margin-bottom: 0.5rem;

  a {
    text-decoration: none;
    font-size: 20px;
    margin-right: 1.3rem;
    color: black;
  }

  a:visited {
    color: black;
  }

  a:hover {
    color: rgb(205, 10, 30);
  }
`;

const FooterLogo = styled.div`
  img {
    object-fit: contain;
    height: 90px;
    width: 200px;
  }
`;

const Copyright = styled.a`
  text-decoration: underline;
  font-size: 15px;
  color: #828282;

  &:visited {
    color: #828282;
  }

  &:hover {
    color: rgb(205, 10, 30);
  }
`;

export const FooterContent = observer(function Navigation() {
  const app = useApp();

  return (
    React.createElement(React.Fragment, null
      , React.createElement(Footer, {__self: this, __source: {fileName: _jsxFileName, lineNumber: 84}}
        , React.createElement(Container, {__self: this, __source: {fileName: _jsxFileName, lineNumber: 85}}
          , React.createElement(FooterInfo, {__self: this, __source: {fileName: _jsxFileName, lineNumber: 86}}
            , React.createElement(Info, {__self: this, __source: {fileName: _jsxFileName, lineNumber: 87}}
              , React.createElement(Link, { to: "impressum", __self: this, __source: {fileName: _jsxFileName, lineNumber: 88}}, "Impressum")
              , React.createElement(Link, { to: "privacy", __self: this, __source: {fileName: _jsxFileName, lineNumber: 89}}, "Datenschutz")
            )
            , React.createElement(Copyright, {
              href: "https://www.fit.fraunhofer.de/",
              target: "_blank",
              title: "Zur Startseite von Fraunhofer FIT"    ,
              rel: "noopener noreferrer" , __self: this, __source: {fileName: _jsxFileName, lineNumber: 91}}
, "Programmiert von Fraunhofer FIT"

              , React.createElement('br', {__self: this, __source: {fileName: _jsxFileName, lineNumber: 98}}), "Grafiken: Fraunhofer FIT"

            )
          )
          , React.createElement(FooterLogo, {__self: this, __source: {fileName: _jsxFileName, lineNumber: 102}}
            , React.createElement('a', {
              href: "https://www.mittelstand-digital.de/MD/Navigation/DE/Home/home.html",
              target: "_blank", __self: this, __source: {fileName: _jsxFileName, lineNumber: 103}}

              , React.createElement('img', {
                src: require("../assets/logo/footer-logos.png"),
                alt: "Bundesministerium für Wirschaft und Klimaschutz"    , __self: this, __source: {fileName: _jsxFileName, lineNumber: 107}}
              )
            )
          )
        )
      )
    )
  );
});

const _jsxFileName = "/usr/src/app/app/js/components/AdminContactsRoute.tsx";import React from "react";
import { observer } from "mobx-react-lite";
import { useApp } from "../context";
import { Button, makeStyles, Typography } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  padding: {
    padding: theme.spacing(2),
  },
  marginBottom: {
    marginBottom: theme.spacing(2),
  },
  downloadButton: {
    // float: "right",
  },
}));

export const AdminContactsRoute = observer(function AdminContactsRoute() {
  const app = useApp();
  const classes = useStyles();

  // TODO: Check auth/permission

  return (
    React.createElement('div', { className: classes.padding, __self: this, __source: {fileName: _jsxFileName, lineNumber: 25}}
      , React.createElement(Typography, { variant: "h5", component: "h2", className: classes.marginBottom, __self: this, __source: {fileName: _jsxFileName, lineNumber: 26}}, "Kontakte"

      )
      , React.createElement(Typography, { variant: "body1", className: classes.marginBottom, __self: this, __source: {fileName: _jsxFileName, lineNumber: 29}}, "Hier klicken, um alle Kontaktdaten zu exportieren."

      )
      , React.createElement(Button, {
        variant: "contained",
        size: "small",
        color: "primary",
        className: classes.downloadButton,
        onClick: () => {
          app.admin.downloadContacts();
        }, __self: this, __source: {fileName: _jsxFileName, lineNumber: 32}}
, "Herunterladen"

      )
    )
  );
});

const _jsxFileName = "/usr/src/app/app/js/components/BenchmarkCompletedRoute.tsx";import React from "react";
import { observer } from "mobx-react-lite";

import { Grid } from "@material-ui/core";

import { SuccessMessage } from "./SuccessMessage";

export const BenchmarkCompletedRoute = observer(
  function BenchmarkCompletedRoute() {
    return (
      React.createElement(Grid, { container: true, justifyContent: "center", spacing: 1, __self: this, __source: {fileName: _jsxFileName, lineNumber: 11}}
        , React.createElement(Grid, { item: true, xs: 12, __self: this, __source: {fileName: _jsxFileName, lineNumber: 12}}
          , React.createElement(SuccessMessage, {
            icon: "fa:check",
            title: "Du hast deinen Benchmark abgeschlossen, vielen Dank"      ,
            message: "Du erhältst innerhalb der nächsten Minuten einen Link zu Deiner Auswertung."          , __self: this, __source: {fileName: _jsxFileName, lineNumber: 13}}
          )
        )
      )
    );
  }
);
